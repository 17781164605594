import { IReadonlyObservableArray, IReadonlyObservableValue, ObservableLike } from "azure-devops-ui/Core/Observable";
import { FocusZone, FocusZoneDirection } from "azure-devops-ui/FocusZone";
import { css } from "azure-devops-ui/Util";
import React from "react";
import { useSubscription, useSubscriptionArray } from "../../../common/hooks/useobservable";
import { useResize } from "../../../common/hooks/useresize";
import { useTimeout } from "../../../common/hooks/usetimeout";
import { ICarouselCardProps } from "./carouselcard";

import "./carousel.css";

const maxCardHeight = 200;
const minCardHeight = 170;
const maxCardWidth = 325;
const minCardWidth = 250;
const photoSpacing = 16;

/**
 * The carousel will render each item into a card using some basic the items
 * render function. If the carousel item wants to dynamically appear or not
 * it can implement the filter function and exclude itself dynamically.
 */
export interface ICarouselItem<T = {}> {
  details: T;
  filter?: () => boolean;
  /**
   * unique identifier of the carousel item,
   * necessary for correct keyboard navigation
   */
  id: string;
  order?: number;
  render: (cardProps: ICarouselCardProps<T>) => React.ReactElement;
}

/**
 * Properties used to create and manage an image carousel.
 */
export interface ICarouselProps {
  /**
   * The value of the current card that is rendered first. If a carouselIndex
   * is not supplied the caller can't control which card is displayed first.
   */
  carouselIndex?: IReadonlyObservableValue<number> | number;

  /**
   * An optional css className added to the outer carousel element.
   */
  className?: string;

  /**
   * Set of carousel items that are rendered into the carousel.
   */
  items: IReadonlyObservableArray<ICarouselItem<unknown>> | ICarouselItem<unknown>[];

  /**
   * If the carousel supports internal carousel movement (touch based scrolling etc)
   * the setCarouselIndex method is used to notify the caller of a change in the
   * first visible card.
   */
  setCarouselIndex?: (firstIndex: number) => void;

  /**
   * viewUpdated is an optional delegate that can be supplied to be notified when
   * the carousel changes the number of visible cards.
   */
  viewUpdated?: (cardCount: number) => void;
}

/**
 * Carousel component is used to render a set of photos in a defined area
 * allowing the user to scroll through them.
 */
export function Carousel(props: ICarouselProps): React.ReactElement {
  const { className, carouselIndex, items, setCarouselIndex, viewUpdated } = props;

  const cardCount = React.useRef(0);
  const cardWidth = React.useRef(0);
  const carouselElement = React.useRef<HTMLDivElement>(null);
  const touchCount = React.useRef(0);

  const [carouselWidth, setCarouselWidth] = React.useState(0);

  // When using touch scrolling we want to wait for the latent scrolling events
  // to complete before snapping to a card.
  const { setTimeout } = useTimeout();

  // We want to re-render when the items change.
  const _items = useSubscriptionArray(items);

  // If any of the items support filtered layout we will filter them before
  // doing any layout operations.
  const filteredItems = _items.filter((carouselItem) => (carouselItem.filter ? carouselItem.filter() : true));

  // First sort and order the items
  const _sortedItems = filteredItems.sort((item1: ICarouselItem, item2: ICarouselItem) => {
    return (item1.order || 0) - (item2.order || 0);
  });

  // Make sure we are handling scrolling appropriately in an rtl environment.
  const rtlModifier = document.documentElement.dir === "rtl" ? -1 : 1;

  // When the carousel changes size we need to handle updating the items in
  // the carousel. We want to keep them within a size range and add/remove
  useResize(
    carouselElement,
    React.useCallback((entries) => setCarouselWidth(entries[0].contentRect.width), [])
  );

  // Listen for index changes and scroll to the appropriate index.
  useSubscription(carouselIndex, (carouselIndex) => updateLayout(carouselIndex));

  // After the carousel has rendered we will scroll to the appropriate location.
  React.useLayoutEffect(() => updateLayout(ObservableLike.getValue(carouselIndex)));

  // Compute and save the number of visible cards and the width of the cards.
  cardCount.current = Math.min(_sortedItems.length, Math.floor((carouselWidth + photoSpacing) / (minCardWidth + photoSpacing)));
  cardWidth.current = Math.min(maxCardWidth, (carouselWidth + photoSpacing) / cardCount.current - photoSpacing);

  const defaultElementId = _sortedItems.length ? _sortedItems[0].id : "";

  return (
    <FocusZone direction={FocusZoneDirection.Vertical} handleTabKey={false} skipHiddenCheck={true} focusGroupProps={{ defaultElementId }}>
      <div
        className={css("bolt-carousel", className, "flex-row flex-noshrink scrollbar-hidden", setCarouselIndex ? "overflow-auto" : "overflow-hidden")}
        onScroll={() => {
          if (touchCount.current === 0) {
            // Wait short period of time for scrolling events from touch deceleration
            // to play out, then snap to the nearest card.
            setTimeout(() => snapToCard(), 50);
          }
        }}
        onTouchEnd={() => {
          if (touchCount.current === 1) {
            // Don't snap immediately, allowing just a little time allows the deceleration
            // of the scrolling to move closer to the target card before snapping.
            setTimeout(() => {
              snapToCard();
              touchCount.current--;
            }, 50);
          } else {
            touchCount.current--;
          }
        }}
        onTouchStart={() => touchCount.current++}
        ref={carouselElement}
      >
        <div className="bolt-carousel-container flex-row flex-gap-16" role="list">
          {_sortedItems.map((item, index) => (
            <div className="inline-flex-row" key={index} role="listitem">
              {item.render({
                details: item.details,
                id: item.id,
                height: carouselWidth > 1440 ? maxCardHeight : minCardHeight,
                width: cardWidth.current
              })}
            </div>
          ))}
        </div>
      </div>
    </FocusZone>
  );

  function snapToCard() {
    const updatedIndex = Math.round(Math.abs(carouselElement.current!.scrollLeft) / (cardWidth.current + photoSpacing));
    setCarouselIndex && setCarouselIndex(updatedIndex);
  }

  function updateLayout(updatedIndex?: number) {
    const _carouselElement = carouselElement.current!;

    // Make sure the element is located at the appropriate location.
    _carouselElement.scrollLeft = (updatedIndex || 0) * (cardWidth.current + photoSpacing) * rtlModifier;

    // If the caller requested updates to the layout notify them.
    viewUpdated && viewUpdated(cardCount.current);
  }
}
