import { Button, ExpandableButton } from "azure-devops-ui/Button";
import { ICallout } from "azure-devops-ui/Callout";
import { IReadonlyObservableValue, ObservableValue } from "azure-devops-ui/Core/Observable";
import { FocusZone, FocusZoneDirection } from "azure-devops-ui/FocusZone";
import { IMenuItem, MenuButton, MenuItemType } from "azure-devops-ui/Menu";
import { css } from "azure-devops-ui/Util";
import { Location } from "azure-devops-ui/Utilities/Position";
import type { MediaPlayerClass } from "dashjs";
import React from "react";
import { v4 } from "uuid";
import { Callout } from "../../../common/components/callout/callout";
import { Observer } from "../../../common/components/observer/observer";
import { EventContext } from "../../../common/contexts/event";
import { useMouseTracker } from "../../../common/hooks/usemousetracker";
import { useObservable, useSubscription } from "../../../common/hooks/useobservable";
import { useTimeout } from "../../../common/hooks/usetimeout";
import { useVideoSource } from "../../../common/hooks/usevideosource";
import { format } from "../../../common/utilities/format";
import { noop, preventDefault } from "../../../common/utilities/func";
import { IEventDispatch } from "../../../common/utilities/platformdispatch";
import { getManifestParams } from "../../api/util";
import { Photo } from "../../components/photo/photo";
import { IAuthorizationContext } from "../../contexts/authorization";
import { DateContext } from "../../contexts/date";
import { ISessionContext, SessionContext } from "../../contexts/session";
import { IPhotoContent, IPhotoContentOptions } from "../../hooks/usephotocontent";
import { IDimensions } from "../../types/item";
import { IPhotoDetails, IVideoDetails } from "../../types/photo";
import { formatDuration } from "../../utilities/format";
import { fullscreenSupported } from "../../utilities/layout";
import { EnterFullscreen, ExitFullscreen, Mute, Pause, Play, Settings, Volume } from "../illustration/icons";
import { Slider } from "../slider/slider";

import "./video.css";

const {
  AudioLanguageMenuItem,
  Duration,
  NoCaptionsMenuItem,
  PauseButton,
  PlayButton,
  SettingsButton,
  ShowCaptionsMenuItem,
  ToggleFullscreenButton,
  UnspecifiedLanguageMenuItem,
  VideoAlt,
  VideoMuted,
  VideoNotSupported,
  VideoPlaybackVariable,
  VideoSliderProgress,
  VideoSuspendFeedback,
  VolumeSliderProgress
} = window.Resources.Common;

// We will track whether we have interacted with the document, thus allowing us
// to play videos with audio.
export const audioSuppressed = new ObservableValue(true);

/**
 * Due to potential errors in the playback that can be caused by seeking
 * to 0. We seek to a fraction ahead of 0. Unknown if the issue stems from a
 * problem with our streaming service, or from the dash player.
 *
 * [2669][StreamProcessor][video] Adjusting playback time 5.165 because of gap
 * in the manifest. Seeking by -0.2999999999999998
 */
export const videoStart = 0.01;

// How long should we wait in the stalled/suspended time before showing an error.
const videoFeedbackMs = 10000;

// In development we want to see messages from dashjs about any problems.
// istanbul ignore next - We don't test in development mode, doesn't matter since we arent testing dashjs.
const logLevel = process.env.NODE_ENV === "development" ? 3 : 0;

window.addEventListener("keydown", () => (audioSuppressed.value = false));
window.addEventListener("mousedown", () => (audioSuppressed.value = false));

/**
 * An IDashHttpRequest is used to define the type the DASH player uses to communicate
 * HttpRequests in the media statisitcs.
 */
export interface IDashHttpRequest {
  actualurl?: any;
  responsecode?: any;
  trequest?: any;
  tresponse?: any;
  type?: any;
  url?: any;

  // Given the nature of naming patterns I believe these are intended to be more
  // an internal value but we want to extract some of the headers like ms-cv for
  // debugging and we will handle missing values or mis-typed values.
  _responseHeaders?: any;
  _tfinish?: any;
}

export interface IVideoControls {
  pause: () => void;
  play: () => void;
  seek: (time: number) => void;
}

export interface IVideoFailure {
  code: string;
  message?: string;
  displayMessage: string;
}

export type VideoControlsVisibility = "invisible" | "never" | "visible";

/**
 * Video component properties
 */
export interface IVideoProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  /**
   * Access the videoControls API through this reference.
   */
  controlsRef?: React.MutableRefObject<IVideoControls | undefined>;

  /**
   * Optional set of information that will be presented on top of the phototile.
   * The containing element is marked with the .photo-debug-info css class.
   */
  debugInfo?: React.ReactNode;

  /**
   * Dimensions the video should be presented.
   */
  dimensions: IDimensions;

  /**
   * The getPhotoContent function is used to retrieve the URL of the photo.
   * The URL and status are returned as an observable value, allowing the
   * function to resolve these either immediately, or asynchronously.
   */
  getPhotoContent: (photoId: string, options?: IPhotoContentOptions) => IPhotoContent;

  /**
   * By default the video player is not initialized. Setting loadPlayer to true
   * will initialize it and get it ready for play.
   */
  loadPlayer?: boolean;

  /**
   * When an error occurs a message is overlaid on the video. The message will
   * stay visible by default. Supplying false will remove the error after the
   * message timeout.
   *
   * @default 0
   */
  messageTimeoutMs?: number;

  /**
   * If an error occurs while loading data for the video onLoadFailure will be
   * called and an error message will be displayed.
   */
  onLoadFailure?: () => void;

  /**
   * If the user toggle full screen through the controls the caller will be notified.
   */
  onToggleFullscreen?: () => Promise<void> | undefined;

  /**
   * General photo details about the item being shown.
   */
  photo: IPhotoDetails;

  /**
   * Explicit enstructions on when the controls should be shown.
   *
   * @default never
   */
  showControls?: IReadonlyObservableValue<VideoControlsVisibility> | VideoControlsVisibility;

  /**
   * showPoster controls whether or not a preview image is loaded into the video
   *
   * @default true
   */
  showPoster?: boolean;

  /**
   * Video specific details about the item being shown.
   */
  videoDetails: IVideoDetails;
}

export function Video(props: IVideoProps): React.ReactElement {
  const {
    className,
    controlsRef,
    messageTimeoutMs = 0,
    debugInfo,
    dimensions,
    getPhotoContent,
    loadPlayer = false,
    onLoadFailure,
    onToggleFullscreen,
    photo,
    showControls = "never",
    showPoster = true,
    videoDetails,
    ...videoAttributes
  } = props;

  const dateContext = React.useContext(DateContext);
  const eventContext = React.useContext(EventContext);
  const sessionContext = React.useContext(SessionContext);

  const player = React.useRef<MediaPlayerClass>();
  const playbackSession = React.useRef<string>(v4());
  const startTime = React.useRef(0);
  const timelineCallout = React.useRef<ICallout | undefined>(undefined);
  const timelineElement = React.useRef<HTMLDivElement>(null);
  const ttmlElement = React.useRef<HTMLDivElement>(null);
  const videoElement = React.useRef<HTMLVideoElement>(null);
  const volumeButton = React.useRef<ExpandableButton>(null);
  const volumeElement = React.useRef<HTMLDivElement>(null);
  const waitStartTime = React.useRef(0);

  const [currentTime, setCurrentTime] = useObservable(0);
  const [duration, setDuration] = useObservable(0);
  const [feedback, _setFeedback] = useObservable<React.ReactNode>(undefined);
  const [mutedVolume, setMutedVolume] = useObservable(0);
  const [playing, setPlaying] = useObservable(false);
  const [recentFocus, setRecentFocus] = useObservable(false);
  const [timelineLocation, setTimelineLocation] = useObservable(-1);
  const [videoLoaded, setVideoLoaded] = React.useState(false);
  const [volume, setVolume] = useObservable(1);

  const { hasMouse, onMouseEnter, onMouseLeave } = useMouseTracker();
  const { onMouseEnter: onTimelineEnter, onMouseLeave: onTimelineLeave } = useMouseTracker({
    leaveDelay: 200,
    onMouseChange: (hasMouse) => {
      return setTimelineLocation(hasMouse ? timelineLocation.value : -timelineLocation.value);
    }
  });

  const { onMouseEnter: onVolumeEnter, onMouseLeave: onVolumeLeave } = useMouseTracker({
    leaveDelay: 50,
    onMouseChange: (hasMouse) => {
      return hasMouse ? volumeButton.current?.expand() : volumeButton.current?.collapse();
    }
  });

  const { setTimeout: setFocusTimeout } = useTimeout();
  const { clearTimeout: clearMessageTimeout, setTimeout: setMessageTimeout } = useTimeout();
  const { setTimeout: setTelemetryTimeout } = useTimeout({ preserve: true });
  const { clearTimeout: clearWaitingTimeout, setTimeout: setWaitingTimeout } = useTimeout();

  // When and if audio is supported we want to make sure we are playing the video
  // with audio when it was requested.
  const _audioSuppressed = useSubscription(audioSuppressed);

  // Expose most of the videoElement as the public API for this component.
  React.useImperativeHandle(controlsRef, () => ({
    pause: () => {
      if (videoElement.current) {
        if (playing.value) {
          videoElement.current.pause();
        }
      }
    },

    play: () => {
      if (videoElement.current) {
        videoElement.current.play().catch(noop);
      }
    },

    seek: (value: number) => {
      if (videoElement.current) {
        videoElement.current.currentTime = value;
      }
    }
  }));

  const { apiOrigin, apiVersion } = sessionContext;

  // When requesting the manifest we MUST return a requestable network URL. We
  // can't return an objectURL. We will use the basic api format with the
  // access_token added.
  // @NOTE: This ties the video component to the current API formation, we should
  // extract this to a function that supplies this and its modifiers to make this
  // more resuable.
  const driveId = photo.parentReference?.driveId ?? sessionContext.driveId;
  const manifestUrl = React.useMemo(() => {
    const params = getManifestParams({ format: "dash" });
    return `${apiOrigin.vroomOrigin}/${apiVersion.apiVersion2}/drives/${driveId}/items/${photo.id}/content?${params.toString()}`;
  }, [apiVersion.apiVersion2, apiOrigin.vroomOrigin, driveId, photo.id]);

  // Now that we have created our video element we will attach the dash player
  // to it with the appropriate URl.
  useVideoSource({
    driveId,
    loadFailure: onLoadFailure,
    loadPlayer,
    manifestUrl,
    photoId: photo.id,
    playbackSession: playbackSession.current,
    player,
    setFeedback,
    logLevel,
    ttmlElement,
    videoElement
  });

  // Start our initial load timer when we demand the player to load
  if (loadPlayer && startTime.current === 0) {
    startTime.current = performance.now();
  }

  return (
    <>
      <div className={css(className, "relative flex-row")} style={{ height: dimensions.height, width: dimensions.width }}>
        <video
          aria-label={format(VideoAlt, {
            videoName: photo.name,
            videoLength: dateContext.formatDurationLabel(videoDetails.duration)
          })}
          {...videoAttributes}
          className="video"
          height={dimensions.height}
          muted={_audioSuppressed || videoAttributes.muted}
          onDurationChange={onDurationChange}
          onEnded={onEnded}
          onLoadedData={onLoadedData}
          onPause={onPause}
          onPlay={onPlay}
          onPlaying={onPlaying}
          onTimeUpdate={onTimeUpdate}
          onVolumeChange={onVolumeChange}
          onWaiting={onWaiting}
          ref={videoElement}
          width={dimensions.width}
        >
          {VideoNotSupported}
        </video>
        <div className="video-ttml-overlay" ref={ttmlElement} />
      </div>
      {!videoLoaded && showPoster && (
        <Photo aria-hidden={true} className="absolute" dimensions={dimensions} draggable={false} getPhotoContent={getPhotoContent} photo={photo} />
      )}
      <Observer values={{ currentTime, duration, playing, recentFocus, showControls }}>
        {({ currentTime, duration, playing, recentFocus, showControls }) => {
          const visible = hasMouse || recentFocus || showControls === "visible";
          const calloutClassName = !visible ? "hidden" : "";
          const settingsMenuItems = getSettingsMenuItems(player, eventContext);

          return videoLoaded && showControls !== "never" ? (
            <div
              className="video-controls-overlay absolute-fill"
              onClick={preventDefault}
              onFocus={onFocus}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <div
                className={css("video-controls pointer-events-auto padding-vertical-12 padding-horizontal-20", visible && "visible")}
                onDoubleClick={preventDefault}
              >
                <div
                  className="flex-column margin-bottom-4 margin-top-8"
                  onMouseEnter={onTimelineEnter}
                  onMouseLeave={onTimelineLeave}
                  onMouseMove={(event) => setTimelineLocation(event.clientX)}
                  ref={timelineElement}
                >
                  <Slider
                    ariaValueText={format(VideoSliderProgress, {
                      currentTime: Math.floor(currentTime),
                      duration: Math.floor(duration)
                    })}
                    className="video-time-slider video-slider"
                    min={videoStart}
                    max={duration}
                    onChange={(value) => {
                      setTelemetryTimeout(
                        () => eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "changeVideoSlider" }),
                        250
                      );

                      // reset buffering time since we seeked
                      waitStartTime.current = 0;

                      // istanbul ignore else - Con't figure out how to test this, we have live site errors.
                      if (videoElement.current) {
                        videoElement.current.currentTime = value;
                      }
                    }}
                    pivot="start"
                    step={0.1}
                    value={currentTime}
                  />
                  <Observer values={{ timelineLocation }}>
                    {({ timelineLocation }) => {
                      if (duration && timelineLocation >= 0) {
                        const timelineRect = timelineElement.current!.getBoundingClientRect();
                        const percentage = Math.min(timelineRect.width, Math.max(0, timelineLocation - timelineRect.left)) / timelineRect.width;

                        if (timelineCallout.current) {
                          timelineCallout.current.updateLayout();
                        }

                        return (
                          <Callout
                            anchorPoint={{ x: timelineLocation, y: timelineRect.top - 8 }}
                            calloutOrigin={{ horizontal: Location.center, vertical: Location.end }}
                            componentRef={timelineCallout}
                            contentClassName="padding-8 rounded-4 depth-16"
                            fixedLayout={true}
                          >
                            {formatDuration(Math.floor(duration * percentage * 1000))}
                          </Callout>
                        );
                      }

                      return null;
                    }}
                  </Observer>
                </div>
                <div className="flex-row">
                  <span>{formatDuration(Math.floor(Math.min(duration, currentTime) * 1000))}</span>
                  <FocusZone
                    direction={FocusZoneDirection.Horizontal}
                    focusGroupProps={{ defaultElementId: "video-play-pause-button" }}
                    handleTabKey={false}
                  >
                    <div className="flex-row flex-justify-center flex-grow padding-top-8 rhythm-horizontal-4" role="list">
                      <Button
                        ariaLabel={playing ? PauseButton : PlayButton}
                        className="transparent"
                        iconProps={{
                          render: (className: string) => (playing ? <Pause className={className} /> : <Play className={className} />)
                        }}
                        id="video-play-pause-button"
                        onClick={(event) => {
                          if (playing) {
                            eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "pauseVideo" });
                            videoElement.current!.pause();
                          } else {
                            eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "playVideo" });
                            videoElement.current!.play().catch(noop);
                          }

                          event.preventDefault();
                        }}
                        tooltipProps={{ className: calloutClassName, text: playing ? PauseButton : PlayButton }}
                      />
                      <Observer values={{ volume }}>
                        {({ volume }) => (
                          <div
                            className="flex-row"
                            onBlur={() => volumeButton.current?.collapse()}
                            onFocus={() => volumeButton.current?.expand()}
                            onMouseEnter={onVolumeEnter}
                            onMouseLeave={onVolumeLeave}
                          >
                            <ExpandableButton
                              anchorOffset={{ horizontal: 0, vertical: 8 }}
                              anchorOrigin={{ horizontal: Location.center, vertical: Location.end }}
                              ariaLabel={volume > 0 ? format(VolumeSliderProgress, { volume: Math.round(volume * 100) + "%" }) : VideoMuted}
                              buttonClassName="transparent"
                              containerRef={volumeElement}
                              dropdownOrigin={{ horizontal: Location.center, vertical: Location.start }}
                              hideDropdownIcon={true}
                              iconProps={{
                                render: (className: string) => (volume === 0 ? <Mute className={className} /> : <Volume className={className} />)
                              }}
                              id="video-volume-button"
                              onClick={(event) => {
                                eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "updateVolume" });

                                if (videoElement.current!.volume) {
                                  setMutedVolume(videoElement.current!.volume);
                                  videoElement.current!.volume = 0;
                                } else {
                                  videoElement.current!.volume = mutedVolume.value;
                                }

                                event.preventDefault();
                              }}
                              onKeyDown={(event) => {
                                if (event.key === "ArrowDown") {
                                  eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "decreaseVolume" });
                                  videoElement.current!.volume = Math.max(0, videoElement.current!.volume - 0.01);
                                  event.preventDefault();
                                } else if (event.key === "ArrowUp") {
                                  eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "increaseVolume" });
                                  videoElement.current!.volume = Math.min(1, videoElement.current!.volume + 0.01);
                                  event.preventDefault();
                                }
                              }}
                              ref={volumeButton}
                              renderCallout={(_dropdown, id, anchorElement, anchorOffset, anchorOrigin, anchorPoint, dropdownOrigin) => (
                                <Callout
                                  anchorElement={anchorElement}
                                  anchorOffset={anchorOffset}
                                  anchorOrigin={anchorOrigin}
                                  anchorPoint={anchorPoint}
                                  calloutOrigin={dropdownOrigin}
                                  className={css("depth-16", calloutClassName)}
                                  contentClassName="rounded-8"
                                  id={id}
                                >
                                  <div className="volume-controls flex-column flex-align-center flex-grow rounded-4" onMouseDown={preventDefault}>
                                    <span className="margin-horizontal-8 margin-top-8">{Math.round(volume * 100)}</span>
                                    <Slider
                                      ariaValueText={format(VolumeSliderProgress, { volume: Math.round(volume * 100) + "%" })}
                                      className="video-volume-slider video-slider margin-vertical-8"
                                      excludeTabStop={true}
                                      min={0}
                                      max={1}
                                      onChange={(value) => {
                                        eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "clickVideoSliderVolume" });
                                        videoElement.current!.volume = 1 - value;
                                      }}
                                      orientation="vertical"
                                      pivot="end"
                                      step={0.01}
                                      value={1 - volume}
                                    />
                                  </div>
                                </Callout>
                              )}
                              tooltipProps={{}}
                            />
                          </div>
                        )}
                      </Observer>
                      {settingsMenuItems.length > 1 && (
                        <MenuButton
                          ariaLabel={SettingsButton}
                          buttonClassName="transparent"
                          contextualMenuProps={() => {
                            return {
                              anchorOffset: { horizontal: 0, vertical: -8 },
                              anchorOrigin: { horizontal: Location.center, vertical: Location.start },
                              className: css("video-quality-menu", calloutClassName),
                              menuOrigin: { horizontal: Location.center, vertical: Location.end },
                              menuProps: {
                                className: "compact-menu status-menu",
                                id: "videoSettings",
                                items: settingsMenuItems
                              }
                            };
                          }}
                          hideDropdownIcon={true}
                          iconProps={{
                            render: (className: string) => <Settings className={className} />
                          }}
                          id="video-settings-button"
                          tooltipProps={{ className: calloutClassName, text: SettingsButton }}
                        />
                      )}
                      {onToggleFullscreen && fullscreenSupported() && (
                        <Button
                          ariaLabel={ToggleFullscreenButton}
                          className="transparent"
                          iconProps={{
                            render: (className: string) =>
                              document.fullscreenElement ? <ExitFullscreen className={className} /> : <EnterFullscreen className={className} />
                          }}
                          id="video-fullscreen-button"
                          onClick={(event) => {
                            if (document.fullscreenElement) {
                              eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "exitFullscreen" });
                            } else {
                              eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "enterFullscreen" });
                            }

                            onToggleFullscreen();
                            event.preventDefault();
                          }}
                          tooltipProps={{ className: calloutClassName, text: ToggleFullscreenButton }}
                        />
                      )}
                    </div>
                  </FocusZone>
                  <span aria-label={format(Duration, { duration: dateContext.formatDurationLabel(duration * 1000) })}>
                    {formatDuration(Math.floor(duration * 1000))}
                  </span>
                </div>
              </div>
            </div>
          ) : null;
        }}
      </Observer>
      <Observer values={{ feedback }}>
        {({ feedback }) =>
          feedback ? (
            <span className="absolute-fill flex-row flex-align-center flex-justify-center padding-8 pointer-events-none">
              <span className="video-feedback body-s text-center white-space-normal padding-16 rounded-6">{feedback}</span>
            </span>
          ) : null
        }
      </Observer>
      {debugInfo && <div className="photo-debug-info absolute-fill sub-layer pointer-events-none">{debugInfo}</div>}
    </>
  );

  function onDurationChange(event: React.SyntheticEvent<HTMLVideoElement>) {
    setDuration(videoElement.current!.duration);
    videoAttributes.onDurationChange && videoAttributes.onDurationChange(event);
  }

  function onEnded(event: React.SyntheticEvent<HTMLVideoElement>) {
    setPlaying(false);
    videoAttributes.onEnded && videoAttributes.onEnded(event);
  }

  function onFocus() {
    setRecentFocus(true);
    setFocusTimeout(() => setRecentFocus(false), 2000);
  }

  function onLoadedData(event: React.SyntheticEvent<HTMLVideoElement>) {
    setVideoLoaded(true);

    const duration = performance.now() - startTime.current;
    eventContext.dispatchEvent("telemetryAvailable", {
      action: "performance",
      duration,
      itemId: photo.id,
      name: "videoReady",
      playbackId: playbackSession.current,
      videoLength: videoDetails.duration
    });

    videoAttributes.onLoadedData && videoAttributes.onLoadedData(event);
  }

  function onPause(event: React.SyntheticEvent<HTMLVideoElement>) {
    setPlaying(false);
    videoAttributes.onPause && videoAttributes.onPause(event);
  }

  function onPlay(event: React.SyntheticEvent<HTMLVideoElement>) {
    setPlaying(true);
    videoAttributes.onPlay && videoAttributes.onPlay(event);
  }

  function onPlaying(event: React.SyntheticEvent<HTMLVideoElement>) {
    clearWaitingTimeout();
    setFeedback(undefined);
    videoAttributes.onPlaying && videoAttributes.onPlaying(event);
  }

  function onTimeUpdate(event: React.SyntheticEvent<HTMLVideoElement>) {
    // istanbul ignore else - not able to produce event while unmounted.
    if (videoElement.current) {
      const videoCurrentTime = videoElement.current.currentTime;
      setCurrentTime(videoCurrentTime);

      // Finished buffering
      const now = performance.now();
      if (waitStartTime.current > 0) {
        const duration = now - waitStartTime.current;

        // On rare occasion have noticed micro-buffering on local machine, so only report buffering over 0.1s
        if (duration > 100) {
          eventContext.dispatchEvent("telemetryAvailable", {
            action: "performance",
            duration,
            itemId: photo.id,
            name: "videoWait",
            playbackId: playbackSession.current,
            videoCurrentTime,
            videoLength: videoDetails.duration
          });
        }

        waitStartTime.current = 0;
      }
    }

    videoAttributes.onTimeUpdate && videoAttributes.onTimeUpdate(event);
  }

  function onVolumeChange(event: React.SyntheticEvent<HTMLVideoElement>) {
    setVolume(videoElement.current!.volume);
    videoAttributes.onVolumeChange && videoAttributes.onVolumeChange(event);
  }

  function onWaiting(event: React.SyntheticEvent<HTMLVideoElement>) {
    setWaitingTimeout(() => {
      if (playing.value) {
        eventContext.dispatchEvent("telemetryAvailable", {
          action: "exception",
          error: "waitForTooLong",
          name: "videoPlayback",
          playbackId: playbackSession.current
        });
        setFeedback(VideoSuspendFeedback);
      }
    }, videoFeedbackMs);

    waitStartTime.current = performance.now();

    videoAttributes.onWaiting && videoAttributes.onWaiting(event);
  }

  function setFeedback(displayMessage: React.ReactNode | undefined): void {
    _setFeedback(displayMessage);

    // If a message is set or cleared we need to manage the display.
    if (messageTimeoutMs) {
      if (displayMessage) {
        setMessageTimeout(() => _setFeedback(undefined), messageTimeoutMs);
      } else {
        clearMessageTimeout();
      }
    }
  }
}

export function authorizationRequestModifier(
  authorizationContext: IAuthorizationContext,
  sessionContext: ISessionContext
): () => { modifyRequest: (request: { url: string; headers: { [key: string]: string } }) => Promise<void> } {
  return () => {
    return {
      modifyRequest: function (request: { url: string; headers: { [key: string]: string } }) {
        const url = new URL(request.url);

        // Lookup the configuration for the target domain.
        const domainConfiguraton = sessionContext.domainSettings[url.hostname];

        if (domainConfiguraton) {
          // We need to build the manifestURL for the video, authorization will be
          // added through a callback, leave it out of the base URL.
          const authkey = authorizationContext.authkey;
          const badgerToken = authorizationContext.badgerToken;

          // If this domain supports the badger process either with authkey's or direct
          // badger tokens we will add any available to the requests.
          if (domainConfiguraton.badger && badgerToken) {
            request.headers["Authorization"] = `Badger ${badgerToken}`;
          } else if (domainConfiguraton.badger && authkey) {
            url.searchParams.append("authkey", authkey);
            request.url = url.toString();
          } else {
            // Determine if the domain settings require a scoped token.
            return authorizationContext.accessToken(domainConfiguraton.scope).then((accessToken) => {
              if (accessToken) {
                if (domainConfiguraton.supportsAccessParam) {
                  url.searchParams.append("access_token", accessToken);

                  // Modify url adding a custom query string parameter
                  request.url = url.toString();
                } else {
                  request.headers["Authorization"] = `Bearer ${accessToken}`;
                }
              }
            });
          }
        }

        return Promise.resolve();
      }
    };
  };
}

export function getSettingsMenuItems(player: React.MutableRefObject<MediaPlayerClass | undefined>, eventDispatch: IEventDispatch): IMenuItem[] {
  const menuItems: IMenuItem[] = [];
  const _player = player.current;

  if (_player) {
    // Only show the quality selections if multiple are available.
    const qualities = _player.getBitrateInfoListFor("video");
    if (qualities.length > 1) {
      const currentQuality = _player.getQualityFor("video");

      qualities.forEach((quality, index, qualities) => {
        const height = quality.height;

        // We want to show bitrate for qualities with multiple rates.
        const secondaryText =
          (index > 0 && qualities[index - 1].height === height) || (index < qualities.length - 1 && qualities[index + 1].height === height)
            ? `${Math.floor(quality.bitrate / 1000)} kbps`
            : undefined;

        menuItems.splice(0, 0, {
          checked: currentQuality === index,
          className: "simple-checkbox",
          data: index,
          id: height.toString(),
          onActivate: () => {
            eventDispatch.dispatchEvent("telemetryAvailable", { action: "userAction", name: "setQuality", value: height });
            _player.updateSettings({ streaming: { abr: { autoSwitchBitrate: { video: false } } } });
            _player.setQualityFor("video", index, true);
          },
          readonly: true,
          secondaryText,
          text: `${quality.height}p${quality.height >= 720 ? " HD" : ""}`
        });
      });

      menuItems.push({ id: "separator", itemType: MenuItemType.Divider });

      menuItems.push({
        checked: !!_player.getSettings().streaming?.abr?.autoSwitchBitrate?.video,
        className: "simple-checkbox",
        id: "auto",
        onActivate: () => {
          eventDispatch.dispatchEvent("telemetryAvailable", { action: "userAction", name: "setQuality", value: "auto" });
          _player.updateSettings({ streaming: { abr: { autoSwitchBitrate: { video: true } } } });
        },
        text: VideoPlaybackVariable,
        readonly: true
      });
    }

    // Setup the ability to control audio language
    const audioTracks = _player.getTracksFor("audio");
    if (audioTracks.length > 1) {
      const currentAudioTrack = _player.getCurrentTrackFor("audio");

      menuItems.push({
        className: "compact-menu status-menu",
        id: "audio",
        text: AudioLanguageMenuItem,
        subMenuProps: {
          className: "compact-menu status-menu",
          id: "audio-track-submenu",
          items: audioTracks.map((audioTrack, index) => ({
            checked: currentAudioTrack === audioTracks[index],
            className: "simple-checkbox",
            id: `${index}`,
            onActivate: () => {
              eventDispatch.dispatchEvent("telemetryAvailable", { action: "userAction", name: "setAudio", value: audioTrack.lang });
              _player.setCurrentTrack(audioTracks[index]);
            },
            readonly: true,
            text: audioTrack.lang || UnspecifiedLanguageMenuItem
          }))
        }
      });
    }

    // Setup the ability to control the captions (singularly, or by language).
    const textTracks = _player.getTracksFor("text");
    if (textTracks.length) {
      const textTrackIndex = _player.getCurrentTextTrackIndex();

      if (textTracks.length === 1) {
        menuItems.push({
          checked: textTrackIndex === 0,
          className: "simple-checkbox",
          id: "text",
          onActivate: () => {
            const enabled = textTrackIndex >= 0;

            eventDispatch.dispatchEvent("telemetryAvailable", {
              action: "userAction",
              name: "setCaption",
              value: enabled ? "disabled" : "enabled"
            });

            _player.setTextTrack(enabled ? -1 : 0);
          },
          readonly: true,
          text: ShowCaptionsMenuItem
        });
      } else {
        menuItems.push({
          id: "text-sub",
          text: ShowCaptionsMenuItem,
          subMenuProps: {
            className: "compact-menu status-menu",
            id: "text-track-submenu",
            items: [
              ...(textTracks
                .map((textTrack, index) => ({
                  checked: textTrackIndex === index,
                  className: "simple-checkbox",
                  id: `${index}`,
                  onActivate: () => {
                    eventDispatch.dispatchEvent("telemetryAvailable", { action: "userAction", name: "setCaption", value: textTrack.lang });
                    _player.setTextTrack(index);
                  },
                  readonly: true,
                  text: textTrack.lang
                }))
                .filter((menuItem) => menuItem) as IMenuItem[]),
              { id: "separator", itemType: MenuItemType.Divider },
              {
                checked: textTrackIndex === -1,
                className: "simple-checkbox",
                id: "text",
                onActivate: () => {
                  eventDispatch.dispatchEvent("telemetryAvailable", { action: "userAction", name: "setCaption", value: "disabled" });
                  _player.setTextTrack(-1);
                },
                readonly: true,
                text: NoCaptionsMenuItem
              }
            ]
          }
        });
      }
    } else {
      // disabled menu item to show that there are no captions available (a11y requirement)
      menuItems.push({
        checked: false,
        disabled: true,
        id: "text",
        readonly: true,
        text: ShowCaptionsMenuItem
      });
    }
  }

  return menuItems;
}
