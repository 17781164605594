import { IResult } from "@ms/utilities-cross-window/lib/interfaces/Results";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { unstable_batchedUpdates } from "react-dom";
import { Observer } from "../../common/components/observer/observer";
import { IEventDispatch } from "../../common/utilities/dispatch";
import { format } from "../../common/utilities/format";
import { noop } from "../../common/utilities/func";
import { createTaskQueue } from "../../common/utilities/taskqueue";
import * as ItemApi from "../api/item";
import * as PhotoApi from "../api/photo";
import { IGetPhotoOptions } from "../api/photo";
import { IAuthorizationContext } from "../contexts/authorization";
import { IEmbedContext } from "../contexts/embed";
import { clearUserCookies, getSignInUri, ISessionContext } from "../contexts/session";
import { IFavoriteEvent, IPhotoCreatedEvent, IPhotosDeletedEvent, IPhotoUpdatedEvent, ITagAddedEvent, ITagDeletedEvent } from "../types/change";
import { IDimensions, IParentReference } from "../types/item";
import { IMessage } from "../types/message";
import { IPhoto, ITag } from "../types/photo";
import { IOpenItemCommand } from "../types/xdm";
import { sendCommand } from "../utilities/embed";
import { executeOperation, IOperationOptions } from "../utilities/operation";
import { getDriveRelativePath, uploadSupported } from "../utilities/util";

const { LoadFailure } = window.Resources.Common;

const {
  AddingTag,
  CompletingDownload,
  DeletingPhotos,
  DeletingTag,
  DownloadFailed,
  DownloadingPhotos,
  DownloadingPhotosViaServer,
  FileNotSupportedError,
  IgnoredFilesMore,
  IgnoredFilesTitle,
  MultiFileDownload,
  UploadFilesTitle
} = window.Resources.PhotoOperations;

const concurrentDeleteOperations = 1;
const concurrentOperations = 5;

const REMOVE_DOWNLOAD_FORM_TIMEOUT = 60000;

// We will set the maximum zipFile size to 2 giga-bytes (2,147,483,648).
const zipSizeLimit = 1024 * 1024 * 1024 * 2;

export function addFavorite(eventDispatch: IEventDispatch, photoIds: string[]): Promise<void> {
  return executeOperation("favoritePhoto", eventDispatch, (createRequest, dispatchChange) => {
    const favorite = createRequest(PhotoApi.addFavorite, { name: "favoritePhoto" });
    dispatchChange<IFavoriteEvent>({ changeType: "favoriteChange", data: { photoIds, isFavorite: true } });

    // Execute the operation and upon success dispatch the change.
    return favorite(photoIds).catch((err) => {
      dispatchChange<IFavoriteEvent>({ changeType: "favoriteChange", data: { photoIds, isFavorite: false } });
      eventDispatch.dispatchEvent("showMessage", { messageType: "error", timeout: 5000, title: err });
    });
  });
}

export function addTag(eventDispatch: IEventDispatch, tagApi: PhotoApi.ITagApi, driveId: string, photoId: string, tag: string): Promise<void> {
  // Details for customer promise data
  const addTagOperationOptions: IOperationOptions = {
    customerPromise: {
      perfGoal: 2000,
      pillar: "Edit"
    }
  };

  return executeOperation(
    "addTag",
    eventDispatch,
    (createRequest, dispatchChange) => {
      const addTag = createRequest(tagApi.addTag, { name: "addTag" });

      // Execute the operation and upon success dispatch the change.
      const addPromise = addTag(driveId, photoId, tag).then(() => {
        dispatchChange<ITagAddedEvent>({ changeType: "tagAdded", data: { photoId, tag } });
      });

      // Put up a message about adding the tags for the photo.
      eventDispatch.dispatchEvent("showMessage", { messageType: "fyi", promise: addPromise, title: format(AddingTag, { tag }) });

      return addPromise;
    },
    addTagOperationOptions
  );
}

export function deletePhotos(eventDispatch: IEventDispatch, driveId: string, photoIds: string[]): Promise<PromiseSettledResult<void>[]> {
  const completedWork = new ObservableValue(0);

  const deleteMessage: IMessage = {
    completedWork,
    messageType: "progress",
    title: DeletingPhotos,
    totalWork: photoIds.length
  };

  // Details for customer promise data
  const deletePhotosOperationOptions: IOperationOptions = {
    customerPromise: {
      perfGoal: 2000 * photoIds.length,
      pillar: "Delete"
    }
  };

  eventDispatch.dispatchEvent("showMessage", deleteMessage);

  return executeOperation(
    "deletePhotos",
    eventDispatch,
    (createRequest, dispatchChange, operationScenario) => {
      const _deletePhoto = createRequest(PhotoApi.deletePhoto, { name: "deletePhoto" });
      const deleteQueue = createTaskQueue(concurrentDeleteOperations);

      // Create the requests to delete each of the photos.
      const pending = photoIds.map((photoId) =>
        deleteQueue.queueTask(() => _deletePhoto(driveId, photoId).finally(() => (completedWork.value = completedWork.value + 1)))
      );

      return Promise.allSettled(pending).then((results) => {
        const deletedIds = results.filter((result) => result.status === "fulfilled").map((_result, index) => photoIds[index]);

        if (deletedIds.length) {
          dispatchChange<IPhotosDeletedEvent>({ changeType: "photosDeleted", data: { photoIds: deletedIds } });
        }

        operationScenario.log({
          deletedIds
        });

        eventDispatch.dispatchEvent("hideMessage", deleteMessage);

        return results;
      });
    },
    deletePhotosOperationOptions
  );
}

export function deleteTag(eventDispatch: IEventDispatch, tagApi: PhotoApi.ITagApi, driveId: string, photoId: string, tag: ITag): Promise<void> {
  // Details for customer promise data
  const deleteTagOperationOptions: IOperationOptions = {
    customerPromise: {
      perfGoal: 2000,
      pillar: "Edit"
    }
  };

  return executeOperation(
    "deleteTag",
    eventDispatch,
    (createRequest, dispatchChange) => {
      const deleteTag = createRequest(tagApi.deleteTag, { name: "deleteTag" });

      // Execute the operation and upon success dispatch the change.
      const deletePromise = deleteTag(driveId, photoId, tag).then(() => {
        dispatchChange<ITagDeletedEvent>({ changeType: "tagDeleted", data: { photoId, tag: tag.name } });
      });

      // Put up a message about deleting the tags for the photo.
      eventDispatch.dispatchEvent("showMessage", { messageType: "fyi", promise: deletePromise, title: format(DeletingTag, { tag: tag.name }) });

      return deletePromise;
    },
    deleteTagOperationOptions
  );
}

export function downloadPhotos(
  eventDispatch: IEventDispatch,
  authContext: IAuthorizationContext,
  embedContext: IEmbedContext,
  sessionContext: ISessionContext,
  items: IPhoto[],
  enableServerZipDownload: boolean,
  sizeLimit?: number
): Promise<void> {
  if (sessionContext.accountType === "business") {
    return downloadPhotosODB(eventDispatch, embedContext, items);
  } else {
    const photoIds = Array.from(items, (item) => ({ name: item.name, id: item.id, size: item.size }));
    return downloadPhotosODC(eventDispatch, authContext, sessionContext, photoIds, enableServerZipDownload, sizeLimit);
  }
}

function downloadPhotosODC(
  eventDispatch: IEventDispatch,
  authContext: IAuthorizationContext,
  sessionContext: ISessionContext,
  photoIds: Array<{ name?: string; id: string; size?: number }>,
  enableServerZipDownload: boolean,
  sizeLimit: number = zipSizeLimit
): Promise<void> {
  const completedWork = new ObservableValue(0);
  const title =
    !sessionContext.migrated && enableServerZipDownload
      ? new ObservableValue(`${DownloadingPhotos}. ${DownloadingPhotosViaServer}`)
      : new ObservableValue(DownloadingPhotos);

  /* istanbul ignore next - We dont mount the title to run the observer so ignore it. */
  const downloadMessage: IMessage = {
    completedWork,
    messageType: "progress",
    title: <Observer values={{ title }}>{({ title }) => <span>{title}</span>}</Observer>,
    totalWork: photoIds.length
  };

  eventDispatch.dispatchEvent("showMessage", downloadMessage);

  return executeOperation("downloadPhoto", eventDispatch, (createRequest, _dispatchChange, operationScenario) => {
    const authenticated = sessionContext.authenticated();
    const _downloadPhoto = createRequest(PhotoApi.downloadPhoto, { name: "downloadPhoto" });

    let blobPromise: Promise<string>;
    let downloadSize = 0;

    if (photoIds.length === 1) {
      blobPromise = _downloadPhoto(sessionContext.driveId, photoIds[0].id, { photoScale: "full" }).then((blob) => {
        completedWork.value = completedWork.value + 1;
        operationScenario.log({ downloadSize: blob.size, fileCount: 1 });

        return window.URL.createObjectURL(blob);
      });
    } else if (!sessionContext.migrated && enableServerZipDownload) {
      // the average photo size captured via a phone starts around 2MB,
      // so if we don't get the actual size from the server, that is what we add to the summary
      const averagePhonePhotoSize = 1024 * 1024 * 2;
      const ids: string[] = [];
      let sizeOfPhotos = 0;

      photoIds.forEach((photo) => {
        sizeOfPhotos += photo.size ?? averagePhonePhotoSize;
        ids.push(photo.id);
      });

      const zipUrl = new URL("https://storage.live.com/downloadfiles/V1/Zip?");
      const url = new URL(window.location.href);
      const authkey = url.searchParams.get("authkey") || undefined;

      if (!authenticated) {
        if (sizeOfPhotos > zipSizeLimit) {
          clearUserCookies();
          window.location.href = getSignInUri(sessionContext, "");
        } else {
          if (authkey) {
            zipUrl.searchParams.set("authkey", authkey);
          }

          downloadZipWithForm(ids.join(";"), zipUrl);
        }

        blobPromise = new Promise((resolve) => resolve(""));
        return blobPromise.then(() => {
          completedWork.value = photoIds.length;

          operationScenario.log({ downloadSize: sizeOfPhotos, fileCount: photoIds.length, mode: "serverZip" });
        });
      } else {
        return authContext
          .accessToken("OneDrive.ReadWrite")
          .then((accessToken) => {
            zipUrl.searchParams.set("access_token", accessToken);

            downloadZipWithForm(ids.join(";"), zipUrl);

            completedWork.value = photoIds.length;
          })
          .catch(() => {
            return Promise.reject(DownloadFailed);
          })
          .finally(() => {
            operationScenario.log({ downloadSize: sizeOfPhotos, fileCount: photoIds.length, mode: "serverZip" });
          });
      }
    } else {
      blobPromise = import("jszip").then((JSZip) => {
        // Create a task queue that will allow us to start the network requests
        // in a controlled fashion instead of all at once.
        const downloadQueue = createTaskQueue(concurrentOperations);

        // Create the in-memory zip file we will use to compress files into.
        let zipCount = 1;
        let zipFile = new JSZip.default();
        let zipSize = 0;

        // Since we can't create a single zip file over certain sizes we will
        // swtich to a multi file download when the result gets too large.
        // By default we will try to generate a single file.
        let multiFileDownload = false;

        // Download each of the files individually.
        return Promise.all(
          photoIds.map(({ name, id }) =>
            downloadQueue.queueTask(() =>
              // Attempt to download the file, if it fails, give it a single retry.
              _downloadPhoto(sessionContext.driveId, id, { photoScale: "full" })
                .catch(() => _downloadPhoto(sessionContext.driveId, id, { photoScale: "full" }))
                .then((blob) => {
                  let intermediatePromise: Promise<void> | undefined;

                  completedWork.value = completedWork.value + 1;
                  downloadSize += blob.size;

                  // Determine if this blob will fit into the currently planned zip file.
                  if (zipSize && zipSize + blob.size > sizeLimit) {
                    // If this is the first time we have gone over the file size limit, we will
                    // notify the user we are creating multiple files.
                    if (!multiFileDownload) {
                      multiFileDownload = true;

                      eventDispatch.dispatchEvent("showMessage", {
                        completedWork,
                        messageType: "fyi",
                        title: MultiFileDownload,
                        timeout: 10000
                      });
                    }

                    // Perform a download on this partial zip file.
                    intermediatePromise = zipFile.generateAsync({ type: "blob" }).then((blob) => downloadFile(window.URL.createObjectURL(blob)));

                    // Rotate the zip file to a new zip file.
                    zipCount++;
                    zipFile = new JSZip.default();
                    zipSize = 0;
                  }

                  zipSize += blob.size;
                  zipFile.file(name || id, blob);

                  return intermediatePromise;
                })
            )
          )
        )
          .catch(() => {
            operationScenario.log({ downloadSize, fileCount: photoIds.length, zipCount });
            return Promise.reject(DownloadFailed);
          })
          .then(() => {
            title.value = CompletingDownload;
            operationScenario.log({ downloadSize, fileCount: photoIds.length, zipCount });

            return zipFile.generateAsync({ type: "blob" });
          })
          .then((blob) => {
            return window.URL.createObjectURL(blob);
          });
      });
    }

    // Once we have the ObjectUrl we will start the download for it.
    return blobPromise.then(downloadFile).finally(() => {
      eventDispatch.dispatchEvent("hideMessage", downloadMessage);
    });

    function downloadFile(downloadUrl: string): void {
      try {
        const filename =
          photoIds.length === 1
            ? photoIds[0].name || photoIds[0].id
            : `OneDrive ${Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" })
                .format(new Date())
                .replace(",", "")}.zip`;

        const downloadElement = document.createElement("a");

        // Build up a link element used to download the file.
        downloadElement.setAttribute("class", "hidden");
        downloadElement.setAttribute("download", filename);
        downloadElement.setAttribute("href", downloadUrl);

        document.body.appendChild(downloadElement);
        downloadElement.click();

        // Remove the link now that we have completed the download.
        document.body.removeChild(downloadElement);
      } catch (error) {}

      // Make sure we release the download blob once we are done.
      window.URL.revokeObjectURL(downloadUrl);
    }

    function downloadZipWithForm(resids: string, url: URL) {
      const downloadForm = document.createElement("form");

      downloadForm.setAttribute("action", url.toString());
      downloadForm.setAttribute("method", "POST");
      downloadForm.setAttribute("class", "hidden");
      downloadForm.setAttribute("target", "_self");

      const formInput = document.createElement("input");
      formInput.setAttribute("type", "hidden");
      formInput.setAttribute("name", "resids");
      formInput.setAttribute("value", resids);

      downloadForm.appendChild(formInput);

      document.body.appendChild(downloadForm);
      downloadForm.submit();

      window.setTimeout(function () {
        document.body.removeChild(downloadForm);
      }, REMOVE_DOWNLOAD_FORM_TIMEOUT);
    }
  });
}

function downloadPhotosODB(eventDispatch: IEventDispatch, embedContext: IEmbedContext, items: IPhoto[]): Promise<void> {
  const eventName = "downloadPhotosODB";

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (!item.sharepointIds?.listItemId || !item.sharepointIds?.siteUrl) {
      eventDispatch.dispatchEvent("telemetryAvailable", { action: "exception", error: { code: "SharepointInfoMissing" }, name: eventName });
      eventDispatch.dispatchEvent("showMessage", { messageType: "error", title: LoadFailure });
      return Promise.resolve();
    }
  }

  return sendCommand(embedContext, {
    command: {
      command: "download",
      items
    },
    eventName,
    eventDispatch: eventDispatch
  })
    .then((result: IResult) => {
      return;
    })
    .catch(noop); // noop since logging & display error message to user is already taken care.
}

export function loadPhotoDetails(eventDispatch: IEventDispatch, driveId: string, photoId: string, options?: IGetPhotoOptions): Promise<void> {
  return executeOperation("getPhotoDetails", eventDispatch, (createRequest, dispatchChange) => {
    const getPhoto = createRequest(PhotoApi.getPhoto, { name: "getPhotoDetails" });

    return getPhoto(driveId, photoId, options).then((photo) => {
      dispatchChange<IPhotoUpdatedEvent>({
        changeType: "photoUpdated",
        data: {
          photo: {
            ...photo,
            detectedEntities: photo.detectedEntities ?? [],
            tags: photo.tags ?? []
          }
        }
      });
    });
  });
}

export function openPhotoODB(eventDispatch: IEventDispatch, embedContext: IEmbedContext, item: IPhoto): Promise<void | IResult> {
  const eventName = "openPhotoODB";

  if (!item.sharepointIds?.listItemId || !item.sharepointIds?.siteUrl) {
    eventDispatch.dispatchEvent("telemetryAvailable", { action: "exception", error: { code: "SharepointInfoMissing" }, name: eventName });
    eventDispatch.dispatchEvent("showMessage", { messageType: "error", title: LoadFailure });
  } else {
    const command: IOpenItemCommand = {
      command: "open",
      items: [item]
    };

    return sendCommand(embedContext, {
      command,
      eventName,
      eventDispatch: eventDispatch
    }).catch(noop); // noop since logging is already taken care of
  }
  return Promise.resolve();
}

export function removeFavorite(eventDispatch: IEventDispatch, photoIds: string[]): Promise<void> {
  return executeOperation("removeFavoritePhoto", eventDispatch, (createRequest, dispatchChange) => {
    const removeFavorite = createRequest(PhotoApi.removeFavorite, { name: "removeFavoritePhoto" });
    dispatchChange<IFavoriteEvent>({ changeType: "favoriteChange", data: { photoIds, isFavorite: false } });

    // Execute the operation and upon success dispatch the change.
    return removeFavorite(photoIds).catch((err) => {
      dispatchChange<IFavoriteEvent>({ changeType: "favoriteChange", data: { photoIds, isFavorite: true } });
      eventDispatch.dispatchEvent("showMessage", { messageType: "error", timeout: 5000, title: err });
    });
  });
}

export function updatePhoto(eventDispatch: IEventDispatch, driveId: string, photoId: string, photo: Partial<Exclude<IPhoto, "id">>): Promise<IPhoto> {
  // Details for customer promise data
  const updatePhotoOperationOptions: IOperationOptions = {
    customerPromise: {
      perfGoal: 2000,
      pillar: "Social",
      name: "savePhotoUpdate"
    }
  };

  return executeOperation(
    "updatePhoto",
    eventDispatch,
    (createRequest, dispatchChange) => {
      const updatePhoto = createRequest(PhotoApi.updatePhoto, { name: "updatePhoto" });

      // Execute the operation and upon success dispatch the change.
      return updatePhoto(driveId, photoId, photo).then((photo) => {
        dispatchChange<IPhotoUpdatedEvent>({ changeType: "photoUpdated", data: { photo } });
        return photo;
      });
    },
    updatePhotoOperationOptions
  );
}

export function uploadPhoto(
  eventDispatch: IEventDispatch,
  driveId: string,
  file: File,
  parentReference: IParentReference,
  defaultDimensions?: IDimensions
): Promise<IPhoto> {
  return executeOperation("uploadPhoto", eventDispatch, (createRequest, dispatchChange) => {
    const uploadPhoto = createRequest(PhotoApi.uploadPhoto, { name: "uploadPhoto" });

    if (uploadSupported(file)) {
      const parentPath = `root:${getDriveRelativePath(parentReference)}`;

      return uploadPhoto(driveId, file, parentPath).then((photo: IPhoto) => {
        if (defaultDimensions) {
          photo.dimensions = defaultDimensions;
        }
        dispatchChange<IPhotoCreatedEvent>({ changeType: "photoCreated", data: { photo } });
        return photo;
      });
    } else {
      const title = getIgnoredWarning([file]);
      eventDispatch.dispatchEvent("showMessage", { messageType: "fyi", timeout: 5000, title });
      return Promise.reject(FileNotSupportedError);
    }
  });
}

export function uploadPhotos(
  eventDispatch: IEventDispatch,
  driveId: string,
  files: File[],
  getTargetFolder?: (fetch: (url: string, init?: RequestInit) => Promise<Response>) => Promise<string>
): Promise<PromiseSettledResult<IPhoto>[]> {
  return executeOperation("uploadPhotos", eventDispatch, (createRequest, dispatchChange) => {
    const getSpecialFolder = createRequest(ItemApi.getSpecialFolder, { name: "getSpecialFolder" });
    const _getTargetFolder = getTargetFolder && createRequest(getTargetFolder, { name: "getTargetFolder" });
    const uploadPhoto = createRequest(PhotoApi.uploadPhoto, { name: "uploadPhoto" });
    const ignored: File[] = [];
    const uploads: File[] = [];
    let uploadSize = 0;

    // Go through and build the list of files that we will ignore and the set we will upload.
    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      if (uploadSupported(file)) {
        uploads.push(file);
        uploadSize += file.size;
      } else {
        ignored.push(file);
      }
    }

    // If there are any files that we are going to ignore let the user know.
    if (ignored.length) {
      const title = getIgnoredWarning(ignored);
      eventDispatch.dispatchEvent("showMessage", { messageType: "fyi", timeout: 5000, title });
    }

    // If there are any files to be uploaded we will start the operation.
    if (uploads.length) {
      // Get the target folder either using the custom function, or using the "Pictures" special folder.
      const targetFolderPromise: Promise<string> = _getTargetFolder
        ? _getTargetFolder()
        : getSpecialFolder(driveId, "photos").then((folder) => {
            return `root:${getDriveRelativePath(folder.parentReference)}/${folder.name}`;
          });

      // Go through each file and upload it to the default photo location.
      const operationPromise = targetFolderPromise.then((parentPath) => {
        const pendingUploads: Promise<IPhoto>[] = [];

        uploads.forEach((file) => {
          const uploadPromise = uploadPhoto(driveId, file, parentPath)
            .then((photo) => {
              uploaded.value = uploaded.value + file.size;
              return photo;
            })
            .catch((error) => {
              uploaded.value = uploaded.value + file.size;
              throw error;
            });

          pendingUploads.push(uploadPromise);
        });

        return Promise.allSettled(pendingUploads).then((results) => {
          unstable_batchedUpdates(() => {
            for (let index = 0; index < results.length; index++) {
              const photoResult = results[index];

              if (photoResult.status === "fulfilled") {
                dispatchChange<IPhotoCreatedEvent>({ changeType: "photoCreated", data: { photo: photoResult.value } });
              }
            }
          });

          return results;
        });
      });

      const uploaded = new ObservableValue(0);
      const uploadMessage: IMessage = {
        completedWork: uploaded,
        messageType: "progress",
        promise: operationPromise,
        title: UploadFilesTitle,
        totalWork: uploadSize
      };

      eventDispatch.dispatchEvent("showMessage", uploadMessage);

      return operationPromise;
    } else {
      return Promise.resolve([]);
    }
  });
}

function getIgnoredWarning(ignored: File[]) {
  return (
    <div className="flex-column">
      <span className="font-weight-semibold">{IgnoredFilesTitle}</span>
      {ignored.slice(0, 3).map((file) => (
        <span key={file.name}>{file.name}</span>
      ))}
      {ignored.length > 3 && <span className="padding-top-8">{format(IgnoredFilesMore, { count: ignored.length - 3 })}</span>}
    </div>
  );
}
