import React from "react";
import { useEventContextListener } from "../../common/hooks/useeventcontextlistener";
import { IFetchCompleteEvent } from "../../common/utilities/fetch";

interface IRateLimit {
  limit: number;
  remaining: number;
  reset: number;
}

export interface IThrottleContext {
  /**
   * The throttle method is used to determine the level of throttling that
   * exists for a given origin.
   *
   * @param origin The API origin whos throttle to retrieve.
   * @returns The percentage of throttling resources consumed, at 100 (1.0) the
   *  origin will not allow requests. The client can expect 429's in return.
   */
  throttle: (origin: string) => number;
}

export const ThrottleContext = React.createContext<IThrottleContext>({ throttle: () => 0 });

export function useThrottleContext(): IThrottleContext {
  const limits = new Map<string, IRateLimit>();

  /**
   * Listen to the completion of fetch requests and track the rate limits.
   * The rate limits are applied to each origin individually.
   */
  useEventContextListener("fetchComplete", (event: IFetchCompleteEvent) => {
    const { url } = event;

    const _url = new URL(url);
    const origin = _url.origin;
    const limit = fromValue(event.telemetryProperties.rateLimitLimit);

    // Evaluate the current rateLimit values and either update or delete them.
    if (limit) {
      const remaining = fromValue(event.telemetryProperties.rateLimitRemaining);
      const reset = fromValue(event.telemetryProperties.rateLimitReset);

      if (remaining !== undefined && reset !== undefined) {
        limits.set(origin, { limit, remaining, reset });
      }
    } else if (event.result.status === "fulfilled") {
      limits.delete(origin);
    }
  });

  return {
    throttle: (origin: string) => {
      const rateLimit = limits.get(origin);
      return rateLimit ? 1 - rateLimit.remaining / rateLimit.limit : 0;
    }
  };

  function fromValue(value: string | number | boolean | undefined): number | undefined {
    return typeof value === "number" ? value : typeof value === "string" ? Number.parseInt(value) : undefined;
  }
}
