import React from "react";
import { ErrorBoundary } from "../../../common/components/boundary/boundary";
import { IScenarioProps, Scenario } from "../../../common/components/scenario/scenario";
import { EventContext } from "../../../common/contexts/event";
import { IScenarioDetails } from "../../../common/utilities/scenario";
import { ComponentPillar, ICustomerPromiseEvent, IVeto, evaluateScenarioVetoes } from "../../utilities/customerpromise";
import { ErrorBoundaryMessage } from "../messagecenter/messagecenter";

export interface ICustomerPromiseProps extends IScenarioProps {
  /**
   * The performance goal for the customer promise in milliseconds.
   */
  perfGoal: number;

  /**
   * The pillar that the scenario component falls under.
   */
  pillar: ComponentPillar;

  /**
   * Optional function that modifies the list of vetoes based on custom logic.
   */
  modifyVetoes?: (scenarioDetails: IScenarioDetails, vetoes: IVeto[]) => void;
}

/**
 * Wrapper for the Scenario component that dispatches a customerPromise event with the duration of the promise and any vetoes that occurred.
 */
export function CustomerPromise(props: ICustomerPromiseProps & { children: React.ReactNode }): React.ReactElement {
  const { completeCallback, perfGoal, pillar, modifyVetoes, ...scenarioProps } = props;

  const eventContext = React.useContext(EventContext);

  const componentRenderVeto = React.useRef<IVeto | undefined>(undefined);

  // Add the pillar to the properties to isolate child customer promises
  scenarioProps.properties = {
    ...scenarioProps.properties,
    pillar
  };

  return (
    <Scenario {...scenarioProps} completeCallback={completeCallbackWrapper}>
      <ErrorBoundary fallbackComponent={ErrorBoundaryMessage} errorCallback={createComponentRenderVeto}>
        {props.children}
      </ErrorBoundary>
    </Scenario>
  );

  function completeCallbackWrapper<T>(scenarioDetails: IScenarioDetails, result: PromiseSettledResult<T>) {
    if (completeCallback) {
      completeCallback(scenarioDetails, result);
    }

    const customerPromiseDuration = scenarioDetails.endTime - scenarioDetails.startTime;

    const customerPromiseData: ICustomerPromiseEvent = {
      action: "customerPromise",
      duration: customerPromiseDuration,
      properties: scenarioProps.properties,
      pillar,
      resultType: "Success",
      scenarioName: scenarioProps.scenarioName
    };

    // Evaluate potential vetoes
    const vetoes = evaluateScenarioVetoes(scenarioDetails);

    if (customerPromiseDuration > perfGoal) {
      if (findSubScenario(scenarioDetails, checkForThrottledSubScenario)) {
        vetoes.push({
          name: `${pillar}SubScenarioThrottled`,
          errorCode: "One or more sub-scenarios were throttled"
        });
      } else {
        vetoes.push({
          name: `${pillar}PerformanceGoalNotMet`,
          errorCode: "Performance goal not met"
        });
      }
    }

    if (componentRenderVeto.current) {
      vetoes.push(componentRenderVeto.current);
    }

    if (modifyVetoes) {
      modifyVetoes(scenarioDetails, vetoes);
    }

    // ASHA currently only supports one veto. If there are multiple vetoes, pick one at random to reduce bias of the first veto.
    if (vetoes.length > 0) {
      const veto = vetoes[Math.floor(Math.random() * vetoes.length)];

      Object.assign(customerPromiseData, {
        resultType: "Failure",
        veto
      });
    }

    eventContext.dispatchEvent("telemetryAvailable", customerPromiseData);
  }

  function createComponentRenderVeto(error: Error, errorInfo: React.ErrorInfo) {
    componentRenderVeto.current = {
      name: `PhotosWebApp${pillar}ComponentRenderError`,
      errorCode: error.name ?? "UnknownRenderError"
    };
  }

  // Recursively search for a child scenario and return first match
  function findSubScenario(scenarioDetails: IScenarioDetails, condition: (scenario: IScenarioDetails) => boolean): IScenarioDetails | undefined {
    if (condition(scenarioDetails)) {
      return scenarioDetails;
    }

    for (const child of scenarioDetails.children) {
      const result = findSubScenario(child, condition);
      if (result) {
        return result;
      }
    }

    return undefined;
  }

  // Check if a scenario is throttled
  function checkForThrottledSubScenario(scenarioDetails: IScenarioDetails): boolean {
    return scenarioDetails.scenarioName === "throttleChildren";
  }
}
