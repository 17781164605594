import { IReadonlyObservableValue, Observable } from "azure-devops-ui/Core/Observable";

/**
 * An ISelection represents a set of selected items where each selected item is
 * represented by a unique string. It is also observable which will allow
 * consumers to optimally react to selection changes.
 */
export interface ISelection extends IReadonlyObservableValue<Set<string>> {
  readonly multiSelect: boolean;
  readonly pivot: string | undefined;
  readonly selectedCount: number;
  readonly value: Set<string>;

  allSelected: (values: { id: string }[]) => boolean;
  clear: () => void;
  selected: (id: string) => boolean;
  select: (id: string | Iterable<string>, merge?: boolean) => void;
  toggle: (id: string) => void;
  unselect(id: string | Iterable<string>): void;
}

/**
 * A Selection can be created with a set of options that controls how the
 * selection behaves to updates and its initial state.
 */
export interface ISelectionOptions {
  /**
   * multiSelect controls whether one or more items can be selected at once.
   *
   * @default false;
   */
  multiSelect?: boolean;

  /**
   * selected is an optional set of initially selected items.
   */
  selected?: Iterable<string> | null;
}

export class Selection extends Observable<Set<string>> implements ISelection, IReadonlyObservableValue<Set<string>> {
  private _multiSelect: boolean;
  private _pivot: string | undefined;
  private _value = new Set<string>();

  constructor(options?: ISelectionOptions) {
    super();

    const { multiSelect = false, selected } = options || {};
    this._multiSelect = multiSelect;
    this._pivot = undefined;
    this._value = new Set<string>(selected);
  }

  public get multiSelect(): boolean {
    return this._multiSelect;
  }

  public get pivot(): string | undefined {
    return this._pivot;
  }

  public get selectedCount(): number {
    return this._value.size;
  }

  public get value(): Set<string> {
    return this._value;
  }

  public allSelected(values: { id: string }[]) {
    for (let i = 0; i < values.length; i++) {
      if (!this._value.has(values[i].id)) {
        return false;
      }
    }

    return true;
  }

  public clear(): void {
    const unselected = this._value;

    this._pivot = undefined;
    this._value = new Set<string>();

    if (unselected.size) {
      this.notify(new Set<string>(unselected), "unselect");
    }
  }

  public selected(id: string): boolean {
    return this._value.has(id);
  }

  public select(id: string | Iterable<string>, merge?: boolean): void {
    const changes = new Set<string>();
    const ids = typeof id === "string" ? [id] : id;
    const selected = new Set<string>();
    const unselected = new Set<string>();
    let pivot: string | undefined = undefined;
    let count = 0;

    // Go through all the items we are selecting and update the state, tracking both
    // changes and the internal value updates.
    for (const id of ids) {
      pivot = pivot ?? id;
      count++;

      // Track all id's supplied, we dont want to clear any of them in the merge phase
      changes.add(id);

      // Track only the changes to the selected set, not all ids.
      if (!this._value.has(id)) {
        selected.add(id);
        this._value.add(id);
      }
    }

    // If we are not merging we now go through and clear all values that were not previously set.
    if (!merge || !this._multiSelect) {
      this._value.forEach((id) => {
        if (!changes.has(id)) {
          unselected.add(id);
        }
      });

      unselected.forEach((id) => {
        this._value.delete(id);
      });
    }

    // Before we notify listeners about the selection update the pivot if needed.
    if (count === 1) {
      this._pivot = pivot;
    } else if (!merge) {
      this._pivot = undefined;
    }

    // Notify any listeners about the unselected items.
    if (unselected.size) {
      this.notify(unselected, "unselect");
    }

    // Notify the listeners about the selected items.
    if (selected.size) {
      this.notify(selected, "select");
    }
  }

  public toggle(id: string): void {
    const change = new Set<string>();

    // Track the id of the item being toggled for notification.
    change.add(id);

    if (this._value.has(id)) {
      this._pivot = undefined;
      this._value.delete(id);
      this.notify(change, "unselect");
    } else {
      this._pivot = id;
      this._value.add(id);
      this.notify(change, "select");
    }
  }

  public unselect(id: string | Iterable<string>): void {
    const ids = typeof id === "string" ? [id] : id;
    const unselected = new Set<string>();

    // Go through each of the ids supplied and unselect them, track the change.
    for (const id of ids) {
      if (this.value.has(id)) {
        // Clear the pivot when pivot item is unselected.
        if (this._pivot === id) {
          this._pivot = undefined;
        }

        unselected.add(id);
        this.value.delete(id);
      }
    }

    if (unselected.size) {
      this.notify(unselected, "unselect");
    }
  }
}
