import { css } from "azure-devops-ui/Util";
import React from "react";
import { ScenarioContext } from "../../../common/contexts/scenario";
import { useSubscription } from "../../../common/hooks/useobservable";
import { IDeferred, defer } from "../../../common/utilities/promise";
import { IPhotoContent, IPhotoContentOptions } from "../../hooks/usephotocontent";
import { IDimensions } from "../../types/item";
import { IRectangle } from "../../types/layout";
import { IPhotoDetails } from "../../types/photo";
import { formatAltTags } from "../../utilities/format";
import { computeCropRect, computeDownloadScale, cropImage, fitToDimensions, fullScaleSupported } from "../../utilities/image";

import photo80Url from "../../../public/static/media/photo-80.png";

import "./photo.css";

const emptyDimensions: IDimensions = { height: 0, width: 0 };
const minTransitionSize = 750;
const transitionPhotoSize = 256;
const unavailableHeight = 256;
const unavailableWidth = 256;

/**
 * IPhotoImageProps are used to describe how to render a Photo component.
 */
export interface IPhotoImageProps extends React.ImgHTMLAttributes<HTMLElement> {
  /**
   * Does the load of this photo block the current scenario?
   *
   * @default false
   */
  blocking?: boolean;

  /**
   * The rectangle to crop from the photo when rendering it. If the cropping
   * rectangle is larger than the photo in either dimension, the entire photo
   * will be used.
   */
  cropRect?: IRectangle;

  /**
   * Optional set of information that will be presented on top of the photo.
   * The containing element is marked with the .photo-debug-info css class.
   */
  debugInfo?: React.ReactNode;

  /**
   * Size of the photo that should be shown. The dimensions are used to compute
   * the resulting image size. If fixed is set to true, this is the literal
   * image size rendered no matter the incoming photo dimensions. If fixed is
   * not supplied the photo will use fitToDimensions to determine the best size
   * for the photo.
   */
  dimensions: IDimensions;

  /**
   * If you require the full photo you can supply "full", this is useful for
   * image types like GIF, where you need the full content to get the animations
   * where a thumbnail wouldn't animate.
   *
   * Custom dimensions can also be supplied and will be used instead of using
   * the computed the layoutDimensions. This can change photo quality at the
   * cost of download speed.
   */
  downloadScale?: IDimensions | "full" | undefined;

  /**
   * Should the photo fade in when it is shown, or should it show up immediately.
   *
   * @default true
   */
  fadeIn?: boolean;

  /**
   * Using a fixed photo ensures the image appears at the supplied dimensions.
   * By default the photo maintains its actual aspect ratio and either crops
   * or contains the photo based on the crop property.
   *
   * @default: false
   */
  fixed?: boolean;

  /**
   * The getPhotoContent function is used to retrieve the URL of the photo.
   * The URL and status are returned as an observable value, allowing the
   * function to resolve these either immediately, or asynchronously.
   */
  getPhotoContent: (photoId: string, options?: IPhotoContentOptions, preview?: boolean) => IPhotoContent;

  /**
   * NOTE: We redefine the load and error handler slightly. Since there are
   * conditions we want to fire the error handler and we aren't doing this based
   * on an existing event. We will not promise a well defined event. The Error
   * event contains no real value so removing this makes little difference.
   */
  onError?: (event?: React.SyntheticEvent<HTMLElement>) => void;
  onLoad?: (event?: React.SyntheticEvent<HTMLElement>) => void;

  /**
   * The underlying photo that we are showing. This is the set of properties
   * used by the photo component. These are a subset of the systems IPhoto
   * interface.
   */
  photo: IPhotoDetails;

  /**
   * Controls whether or not the component will load the smaller image and
   * transition to the full size once its loaded. Turning this off means the caller
   * owns handling slowly loaded photos. If the caller supplied transition they
   * MUST also supply a parent element that is positioned. The Photo component
   * uses an absolutely positioned element on top of the full photo to perform
   * the transition.
   *
   * @default true
   */
  transition?: boolean;
}

/**
 * The photo component is used to render an IPhoto through an optimized process.
 *
 * If the caller requests a large presentation the component will initially render
 * a smaller version that can be loaded much faster and transition the image to the
 * larger size once its ready.
 *
 * The component will attempt to use the existing pre-auth URL's. If they are no
 * longer valid or working, the component will fallback to use the API and auth
 * tokens to retrieve the photo.
 *
 * @param props The set of properties used to control how the component is rendered.
 * @returns
 */
export function Photo(props: IPhotoImageProps): React.ReactElement {
  const {
    alt,
    blocking = false,
    className,
    cropRect,
    debugInfo,
    dimensions,
    fadeIn = true,
    fixed = false,
    getPhotoContent,
    onError,
    onLoad,
    photo,
    downloadScale,
    transition = true,
    ...elementProps
  } = props;
  const { height: photoHeight, width: photoWidth } = photo.dimensions;

  const scenarioContext = React.useContext(ScenarioContext);

  const canvasElement = React.useRef<HTMLCanvasElement>(null);
  const previewCanvasElement = React.useRef<HTMLCanvasElement>(null);
  const resolvedUrl = React.useRef<string | undefined>(undefined);

  const [failedUrl, setFailedUrl] = React.useState<string | undefined>(undefined);

  const deferredLoad = React.useMemo(() => {
    // If this is a blocking scenario we will create an async wait for rendering the photo.
    let deferredLoad: IDeferred<void> | undefined;

    // Attach this image load to the current scenario if it is blocking.
    if (blocking) {
      const currentScenario = scenarioContext.getScenario();

      if (currentScenario) {
        // Create a async task we will tie to the success/failure of the photo loading
        // for scenario tracking.
        deferredLoad = defer<void>();

        // Attach the imageload to the current scenario
        currentScenario.waitUntil(deferredLoad.promise, { scenarioName: "downloadPhoto", scenarioType: "imageLoad", waitForPaint: false });
      }
    }

    return deferredLoad;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocking, photo.id, downloadScale]);

  React.useEffect(() => {
    return () => {
      // if the component is unmounted and the photo is blocking the scenario,
      // reject the deferredLoad so it doesn't stop the scenario from resolving.
      deferredLoad?.reject({ isCanceled: true, reason: { unmount: true } });
    };
  }, [deferredLoad]);

  // If we are cropping the photo we want to fit to the
  // Get the optimized dimensions to layout this photo.
  const layoutDimensions = fitToDimensions(
    Math.min(dimensions.height, photoHeight || unavailableHeight),
    Math.min(dimensions.width, photoWidth || unavailableWidth),
    cropRect?.height || photoHeight || unavailableHeight,
    cropRect?.width || photoWidth || unavailableWidth,
    { crop: !cropRect }
  );

  // If the image is large enough and the caller wants a transition we will set
  // it up. This means a parallell preview URL. We will get an aspect ratio
  // correct version that fits in a transitionPhotoSize box.
  let previewDimensions: IDimensions | undefined;
  if (transition && (layoutDimensions.height > minTransitionSize || layoutDimensions.width > minTransitionSize)) {
    previewDimensions = fitToDimensions(transitionPhotoSize, transitionPhotoSize, layoutDimensions.height, layoutDimensions.width);
  }

  // Start the request for the thumbnail content url.
  const previewContent = getPhotoContent(
    photo.id,
    {
      cacheToken: photo.lastModifiedDateTime,
      filename: photo.name,
      photoScale: previewDimensions
    },
    true
  );

  // If we are going to crop the image, we need to compute the sourceDimensions
  // we will use for the cropped image.
  const croppedDownloadDimensions: IDimensions = cropRect ? computeDownloadScale(photo.dimensions, cropRect, layoutDimensions) : emptyDimensions;

  // Scale the photo will be downloaded using, this will vary depending on the type
  // of photo and the requested rendering model.
  const photoScale = cropRect
    ? croppedDownloadDimensions
    : downloadScale === "full"
    ? !photo.file || fullScaleSupported(photo.file.mimeType)
      ? "full"
      : photo.dimensions
    : downloadScale || layoutDimensions;

  // If we are cropping the photo compute the download size required based on the
  // cropRect and dimension proportions to the layoutDimensions.
  let scaledCropRect: IRectangle | undefined;
  if (cropRect) {
    scaledCropRect = computeCropRect(photo.dimensions, cropRect, croppedDownloadDimensions);
  }

  let previewScaledCropRect: IRectangle | undefined;
  if (transition && cropRect && previewDimensions) {
    previewScaledCropRect = computeCropRect(photo.dimensions, cropRect, previewDimensions);
  }

  // Get the content for this photo and the status for loading.
  //
  // If the fullsize photo was not requested we will add an explicit width x height.
  // This will reduce the size of very large images. We MUST keep the full
  // photo if it was requested since things like GIF's wont animate without it.
  // This also fixes HEIC photos which don't work in the <img /> tag, by
  // requesting a "thumbnail", it will get translated to a supported format.
  const photoContent = getPhotoContent(photo.id, {
    cacheToken: photo.lastModifiedDateTime,
    filename: photo.name,
    photoScale
  });

  // Determine if the photo should maintain the natural aspect ratio or be laid
  // out with the supplied dimensions.
  const imageDimensions = fixed ? dimensions : layoutDimensions;

  // Compute the shared attributes between the succeeded and failed image tag.
  let _alt = alt;

  if (!alt) {
    if (photo.tags?.length) {
      _alt = formatAltTags(photo);
    } else if (photo.name) {
      _alt = photo.name;
    }
  }

  const loadComplete = React.useCallback(
    (event: React.SyntheticEvent<HTMLElement> | undefined, error?: any): void => {
      if (error) {
        setFailedUrl(photo80Url);
        deferredLoad?.reject(error);
        onError && onError(event);
      } else {
        deferredLoad?.resolve();
        onLoad && onLoad(event);
      }
    },
    [deferredLoad, onError, onLoad, setFailedUrl]
  );

  // Subscribe to the main image values.
  useSubscription(photoContent.status, (status) => {
    // If the main photo fails to load and we haven't loaded a preview, we can
    // transition to the failed photo.
    if (status === "rejected" && previewStatus !== "fulfilled") {
      loadComplete(undefined, { error: { code: "downloadFailed", message: `${photo.id}:${photoUrl}` } });
      return true;
    }
  });

  const photoUrl = useSubscription(photoContent.url, (contentUrl: string) => {
    resolvedUrl.current = contentUrl;
    return true;
  });

  // Subscribe to the preview image values.
  const previewStatus = useSubscription(previewContent.status);
  const previewUrl = useSubscription(previewContent.url);

  React.useMemo(() => {
    if (previewUrl && previewScaledCropRect && previewDimensions) {
      cropImage(previewUrl, previewScaledCropRect, dimensions, previewCanvasElement, previewDimensions);
    }
  }, [dimensions, previewDimensions, previewScaledCropRect, previewUrl]);

  React.useMemo(() => {
    if (photoUrl && scaledCropRect && !failedUrl) {
      cropImage(photoUrl, scaledCropRect, dimensions, canvasElement, croppedDownloadDimensions)
        .then(() => loadComplete(undefined))
        .catch(() => loadComplete(undefined, { error: { code: "cropFailed", message: `${photo.id}:${photoUrl}` } }));
    }
  }, [canvasElement, croppedDownloadDimensions, dimensions, failedUrl, loadComplete, photoUrl, photo.id, scaledCropRect]);

  // Determine whether or not we need to render a debug element on top of the photo.
  const debugElement = debugInfo && (
    <div className="photo-debug-info absolute sub-layer pointer-events-none" style={{ height: imageDimensions.height, width: imageDimensions.width }}>
      {debugInfo}
    </div>
  );

  return failedUrl ? (
    <>
      <div
        aria-label={_alt}
        {...elementProps}
        className={css("photo", className, "photo-failed")}
        key={`f-${photo.id}`}
        style={{ backgroundImage: `url(${failedUrl})`, height: imageDimensions.height, width: imageDimensions.width }}
      />
      {debugElement}
    </>
  ) : (
    <>
      {previewStatus === "fulfilled" ? (
        previewScaledCropRect ? (
          <canvas
            aria-label={_alt}
            className={css("photo-transition photo-canvas", className)}
            height={Math.ceil(imageDimensions.height)}
            key="canvas-overlay"
            ref={previewCanvasElement}
            width={Math.ceil(imageDimensions.width)}
            style={{ height: imageDimensions.height, width: imageDimensions.width }}
          />
        ) : (
          <div
            aria-hidden={true}
            className={css("photo-transition photo-image", className)}
            key="image-overlay"
            style={{ backgroundImage: `url(${previewUrl})`, height: imageDimensions.height, width: imageDimensions.width }}
          />
        )
      ) : null}
      {photoUrl ? (
        scaledCropRect ? (
          <canvas
            aria-label={_alt}
            className={css("photo photo-canvas", className, fadeIn ? "fade-in" : "visible")}
            height={Math.ceil(imageDimensions.height)}
            key={`cs-${photo.id}`}
            ref={canvasElement}
            width={Math.ceil(imageDimensions.width)}
            style={{ height: imageDimensions.height, width: imageDimensions.width }}
          />
        ) : (
          <img
            {...elementProps}
            alt={_alt}
            className={css("photo photo-image", className, fadeIn ? "fade-in" : "visible")}
            key={`is-${photo.id}`}
            onError={(event) => loadComplete(event, { error: { code: "loadFailed", message: `${photo.id}:${photoUrl}` } })}
            onLoad={(event) => loadComplete(event)}
            style={{
              /**
               * NOTE: The background-url inline style is required for screenshots to work
               * properly. Without it the images will not show up in the image.
               */
              backgroundImage: `url(${resolvedUrl.current || photoUrl})`,
              height: imageDimensions.height,
              width: imageDimensions.width
            }}
            src={resolvedUrl.current || photoUrl}
          />
        )
      ) : null}
      {debugElement}
    </>
  );
}
