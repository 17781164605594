import { Button } from "azure-devops-ui/Button";
import { ObservableCollection } from "azure-devops-ui/Core/Observable";
import { IconSize } from "azure-devops-ui/Icon";
import { Link } from "azure-devops-ui/Link";
import { IMenuItem, MenuButton } from "azure-devops-ui/Menu";
import { css, getSafeId, getSafeIdSelector } from "azure-devops-ui/Util";
import { Location } from "azure-devops-ui/Utilities/Position";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Callout } from "../../common/components/callout/callout";
import { lazy } from "../../common/components/lazy/lazy";
import { Observer } from "../../common/components/observer/observer";
import { ResponsiveLayout } from "../../common/components/responsivelayout/responsivelayout";
import { Scenario } from "../../common/components/scenario/scenario";
import { EventContext } from "../../common/contexts/event";
import { FeatureContext } from "../../common/contexts/feature";
import { NavigationContext } from "../../common/contexts/navigation";
import { SettingsContext } from "../../common/contexts/settings";
import { useFocusCapture } from "../../common/hooks/usefocuscapture";
import { useEventListener } from "../../common/hooks/uselistener";
import { useObservable, useObservableArray, useSubscription } from "../../common/hooks/useobservable";
import { useTimeout } from "../../common/hooks/usetimeout";
import { binarySearch } from "../../common/utilities/binarysearch";
import { format, formatComponent } from "../../common/utilities/format";
import { RangeSelection } from "../../common/utilities/rangeselection";
import memoriesUrl from "../../public/static/media/memories.png";
import { getSpecialFolder } from "../api/item";
import { downloadPhoto, getOldestPhoto, getPhotos, getPhotosPage } from "../api/photo";
import { getRecommendations } from "../api/recommendation";
import { Carousel, ICarouselItem } from "../components/carousel/carousel";
import { IImageCarouselCard, ImageCarouselCard } from "../components/carousel/carouselcard";
import { CompactButton } from "../components/compactmenu/compactbutton";
import { DateRange, IDateRange } from "../components/daterange/daterange";
import { FilterMenu, getFilterMenuItems, sanitizeFilter } from "../components/filtermenu/filtermenu";
import * as Icons from "../components/illustration/icons";
import { IllustrationView } from "../components/illustrationview/illustrationview";
import { Layout } from "../components/layout/layout";
import { LayoutMenu, getLayoutMenuItems } from "../components/layoutmenu/layoutmenu";
import { NotificationGroup } from "../components/notificationgroup/notificationgroup";
import { PageCommandbar } from "../components/pagecommandbar/pagecommandbar";
import { PhotoHeader } from "../components/photoheader/photoheader";
import { PhotoTileCommand } from "../components/phototile/phototilecommand";
import { Pivots } from "../components/pivots/pivots";
import { Scrubber } from "../components/scrubber/scrubber";
import { Zoom } from "../components/zoom/zoom";
import { AuthorizationContext } from "../contexts/authorization";
import { BackgroundContext } from "../contexts/background";
import { EmbedContext } from "../contexts/embed";
import { SessionContext } from "../contexts/session";
import { useAlbumApi } from "../hooks/usealbumapi";
import { useCenterItem } from "../hooks/usecenteritem";
import { useFetch } from "../hooks/usefetch";
import { useLayoutStyle } from "../hooks/uselayoutstyle";
import { useAllPhotoFeed } from "../hooks/usephotofeed";
import { useRejection } from "../hooks/userejection";
import { useSetting } from "../hooks/usesetting";
import { useZoom } from "../hooks/usezoom";
import { gridLayout } from "../layout/grid";
import { masonryLayout } from "../layout/masonry";
import { riverLayout } from "../layout/river";
import { addToAlbum, createAlbum } from "../operations/album";
import { deletePhotos, downloadPhotos } from "../operations/photo";
import { FilterFolder, IFilterFolder } from "../types/item";
import { ILayout, ILayoutOptions, IRectangle, ITileDetails, ITilePadding, LayoutAlgorithm, LayoutFunction } from "../types/layout";
import { IPhoto, IPhotoRenderOptions } from "../types/photo";
import { UXSetting } from "../types/settings";
import { IViewProps } from "../types/view";
import { filterAlbumsAuto } from "../utilities/albums";
import { getPhotoTakenDate, getViewRoute, isSameDay, isSimilarPhoto } from "../utilities/image";
import { nameTilePadding, renderNameplatePlaceholder } from "../utilities/layout";
import { createRecentPlacesCards } from "../utilities/recentplaces";
import { createRecommendationCards } from "../utilities/recommendations";
import { getAllSelectedPhotos, getDuplicatedPhotosParent } from "../utilities/util";
import { validateLayoutAlgorithm } from "../utilities/validation";

import "./allphotosview.css";

const AddToAlbumPanel = lazy(() => import("../dialogs/addtoalbumdialog"));
const CreateAlbumDialog = lazy(() => import("../dialogs/createalbumdialog"));
const DeletePhotoDialog = lazy(() => import("../dialogs/deletephotodialog"));
const EmptyPhoto = lazy(() => import("../components/illustration/emptyphoto"));
const ShareDialogODB = lazy(() => import("../dialogs/sharedialogodb"));
const ShareDialogODC = lazy(() => import("../dialogs/sharedialogodc"));

const {
  AccessMemoriesSubtitle,
  AccessMemoriesTitle,
  AddToAlbumButton,
  ClearBackgroundButton,
  ClearSelectionButton,
  DeleteButton,
  DownloadButton,
  MoreButton,
  PhotosList,
  SelectedItems,
  SetBackgroundButton,
  ShareButton,
  ZoomControls
} = window.Resources.Common;

const {
  AllPhotosTitle,
  EmptyPhotoAddNewSubTitle,
  EmptyPhotoSubTitle,
  EmptyPhotoTitle,
  ForYouHeading,
  GalleryHeading,
  MediaTitle,
  ViewNextTiles,
  ViewPreviousTiles
} = window.Resources.AllPhotos;

const compactPhotoSpacing = 2;
const defaultLayoutAlgorithm = "river";
const defaultZoomLevel = 200;
const maxZoomLevel = 500;
const minZoomLevel = 150;
const spaciousPhotoSpacing = 20;

const memoriesCard: ICarouselItem<IImageCarouselCard> = {
  details: {
    className: "standard-size light-background",
    href: "https://www.microsoft.com/en-us/microsoft-365/onedrive/download",
    subtitle: AccessMemoriesSubtitle,
    url: memoriesUrl,
    title: AccessMemoriesTitle
  },
  id: "accessMemoriesCard",
  // see documentation/client/componentspecific/foryoucarousel.md for ordering logic
  order: 1,
  render: (cardProps) => <ImageCarouselCard key="memories" {...cardProps} />
};

const fixedCarouselItems: ICarouselItem<any>[] = [memoriesCard];

/**
 * IAllPhotosViewProps are used to configure the AllPhotosView component.
 */
export interface IAllPhotosViewProps extends IViewProps {}

export function AllPhotosView(props: IAllPhotosViewProps): React.ReactElement {
  const { setDocumentTitle } = props;

  const authorizationContext = React.useContext(AuthorizationContext);
  const backgroundContext = React.useContext(BackgroundContext);
  const embedContext = React.useContext(EmbedContext);
  const eventContext = React.useContext(EventContext);
  const featureContext = React.useContext(FeatureContext);
  const navigationContext = React.useContext(NavigationContext);
  const sessionContext = React.useContext(SessionContext);
  const settingsContext = React.useContext(SettingsContext);

  const [uxSetting, setUxSetting] = useSetting("uxSetting");

  const commandElement = React.useRef<HTMLDivElement>(null);
  const galleryHeaderElement = React.useRef<HTMLDivElement>(null);
  const layout = React.useRef<ILayout<IPhoto> | undefined>(undefined);
  const layoutElement = React.useRef<HTMLDivElement>(null);
  const recentPlacesSet = React.useRef(false);
  const scrollingElement = React.useRef<HTMLDivElement>(null);
  const zoomElement = React.useRef<HTMLDivElement>(null);

  const [callout, setCallout] = useObservable<React.ReactElement | null>(null);
  const [carouselCount, setCarouselCount] = useObservable(0);
  const [carouselIndex, setCarouselIndex] = useObservable(0);
  const [carouselItems] = useObservableArray<ICarouselItem<unknown>>([...fixedCarouselItems]);
  const [dateRange, setDateRange] = useObservable<IDateRange | undefined>(undefined);
  const [filter, setFilter] = React.useState<IFilterFolder>(sanitizeFilter(uxSetting?.galleryFilter));
  const [layoutAlgorithm, setLayoutAlgorithm] = React.useState<LayoutAlgorithm>(
    validateLayoutAlgorithm(uxSetting?.layoutAlgorithm) || defaultLayoutAlgorithm
  );
  const [showAddToAlbumDialog, setShowAddToAlbumDialog] = useObservable(false);
  const [showCreateAlbumDialog, setShowCreateAlbumDialog] = useObservable<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useObservable(false);
  const [showGalleryHeaderBackground, setShowGalleryHeaderBackground] = useObservable(false);
  const [showScrubberContents, setShowScrubberContents] = useObservable(false);
  const [showShareDialog, setShowShareDialog] = useObservable(false);
  const [showZoomPopup, setShowZoomPopup] = useObservable(false);
  const [selection] = React.useState(new RangeSelection(() => items));
  const [targetDate, setTargetDate] = React.useState<Date | undefined>(undefined);
  const [zoomLevel, setZoomLevel] = React.useState(Math.max(minZoomLevel, Math.min(maxZoomLevel, uxSetting?.zoomLevel || defaultZoomLevel)));

  // Setup focus capture for our view.
  const { onBlur, onFocus } = useFocusCapture(getSafeIdSelector("gallery"));

  const layoutStyle = useLayoutStyle();
  const desktopLayout = layoutStyle === "desktop";
  const mobileLayout = layoutStyle === "mobile";
  const tabletLayout = layoutStyle === "tablet";

  // Determine which features we are using at the moment.
  const autoGroupPhotos = useSubscription(featureContext.featureEnabled("autoGroupPhotos"));
  const backgroundImage = useSubscription(featureContext.featureEnabled("backgroundImage"));
  const enableServerZipDownload = useSubscription(featureContext.featureEnabled("EnableServerZipDownload"));
  const showAlbums = useSubscription(featureContext.featureEnabled("showAlbums"));
  const showCompactLayout = useSubscription(featureContext.featureEnabled("showCompactLayout")) || mobileLayout;
  const showFilenames = useSubscription(featureContext.featureEnabled("showFilenames"));
  const showHeaders = useSubscription(featureContext.featureEnabled("showHeaders")) && layoutAlgorithm !== "masonry";

  const { setTimeout: setTelemetryTimeout } = useTimeout({ preserve: true });
  const { setTimeout: setScrubberTimeout } = useTimeout();

  const _getRecommendations = useFetch(getRecommendations, { blocking: false, name: "getRecommendations" });
  const _getSpecialFolder = useFetch(getSpecialFolder, { name: "getSpecialFolder" });

  // Create a rejection handler to communicate failures to the user.
  const handleRejection = useRejection();

  // Get the album api based on migration state.
  const albumApi = useAlbumApi();

  React.useEffect(() => {
    const scrollElement = scrollingElement.current;
    scrollElement?.addEventListener("scroll", onScroll);

    function onScroll() {
      if (galleryHeaderElement.current && scrollElement) {
        const positionFromTop = galleryHeaderElement.current.getBoundingClientRect().top - scrollElement.getBoundingClientRect().top;

        if (positionFromTop === 0) {
          setShowGalleryHeaderBackground(true);
        } else {
          setShowGalleryHeaderBackground(false);
        }
      }
    }

    return () => {
      scrollElement?.removeEventListener("scroll", onScroll);
    };
  });

  // One time initialization of the component.
  React.useState(() => {
    if (!sessionContext.embedded) {
      const recommendations = _getRecommendations(sessionContext.driveId);

      // Cards will be in order : OTD > Other MOJs
      // Once request is complete create the appropriate cards and push
      // them into the carousel items.
      Promise.allSettled([recommendations])
        .then(([recommendedItems]) => {
          unstable_batchedUpdates(() => {
            if (recommendedItems.status === "fulfilled") {
              const recommendationCards = createRecommendationCards(recommendedItems.value);

              // We need to ensure the links are properly prepared before using them.
              for (const carouselItem of recommendationCards) {
                carouselItem.details.href = navigationContext.prepare(carouselItem.details.href);
              }

              carouselItems.push(...recommendationCards);

              const recommendationTypes: string[] = [];
              recommendedItems.value.value.forEach((item) => {
                recommendationTypes.push(item.recommendation.recommendationType);
              });

              eventContext.dispatchEvent("telemetryAvailable", {
                action: "engagement",
                name: "recommendationsLoaded",
                recommendations: recommendationTypes
              });
            }
          });
        })
        .catch(handleRejection);
    }
  });

  setDocumentTitle(sessionContext.embedded ? MediaTitle : AllPhotosTitle);

  const _getPhotos = useFetch(getPhotos, { name: "getPhotos" });
  const _getPhotosPage = useFetch(getPhotosPage, { name: "getPhotosPage" });
  const _getOldestPhoto = useFetch(getOldestPhoto, { name: "getOldestPhoto" });
  const excludeTagsFromAllPhotosClient = useSubscription(featureContext.featureEnabled("excludeTagsFromAllPhotosClient"));

  // Create the data access method used to read photos.
  const getItems = React.useCallback(
    (url?: string) => {
      if (!url) {
        // Make the initial request based on the filters supplied to the hook.
        const expand = excludeTagsFromAllPhotosClient ? [] : ["tags"];
        return _getPhotos(sessionContext.driveId, { id: filter.folderId, initialPageDateTaken: targetDate, expand });
      } else {
        return _getPhotosPage(url);
      }
    },
    [_getPhotos, _getPhotosPage, sessionContext.driveId, filter.folderId, targetDate, excludeTagsFromAllPhotosClient]
  );

  // Get the photo management data from the layout hook.
  const photoFeed = useAllPhotoFeed(getItems, _getOldestPhoto, { ensureData: !!filter.folderId, id: filter.folderId, selection });
  const { feedId, getItem, items, newestPhoto, oldestPhoto } = photoFeed;

  // Rerender once the photoFeed has completed its initialization.
  const initialized = useSubscription(photoFeed.initialized, () => {
    if (!sessionContext.embedded) {
      // The first time the feed is initialized we will generate the recent places cards.
      if (!recentPlacesSet.current && items.length) {
        const recentPlacesCards = createRecentPlacesCards(items.value);

        // We need to ensure the links are properly prepared before using them.
        for (const carouselItem of recentPlacesCards) {
          carouselItem.details.href = navigationContext.prepare(carouselItem.details.href);
        }

        carouselItems.push(...recentPlacesCards);
        recentPlacesSet.current = true;
      }
    }
  });

  // Clear any proposed selection if the user releases the shift key.
  useEventListener(
    window,
    "keyup",
    React.useCallback(
      (event: KeyboardEvent) => {
        if (!event.shiftKey) {
          selection.proposeSelection();
        }
      },
      [selection]
    )
  );

  const { centerOnItem } = useCenterItem(layout);
  const { zoom } = useZoom(setZoomLevel, centerOnItem);

  // When the filter or layoutAlgorithm change we need to recompute our menu items.
  const mobileOverflowMenuItems = React.useMemo(
    () => new ObservableCollection<IMenuItem>(),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, layoutAlgorithm, featureContext, layoutAlgorithm, selectLayout]
  );

  // Build the horizontalSpacing classNames used to get the correct spacing.
  const horizontalSpacing = css("padding-horizontal-4", desktopLayout || tabletLayout ? "margin-left-12 margin-right-40" : "margin-horizontal-12");

  // Get the layout function to use for rendering the photos.
  const layoutFunction = React.useMemo<LayoutFunction<IPhoto>>(() => {
    const layoutOptions: ILayoutOptions<IPhoto, IPhotoRenderOptions> = {
      isSimilar: autoGroupPhotos ? isSimilarPhoto : undefined,
      itemSpacing: showCompactLayout ? compactPhotoSpacing : spaciousPhotoSpacing,
      renderItem: (tileDetails: ITileDetails<IPhoto>, index: number, photoRenderOptions?: IPhotoRenderOptions): React.ReactElement => {
        const { group, item, padding, rect } = tileDetails;
        const { id } = item;

        // Generate the element that represents our tile.
        const element = (
          <PhotoTileCommand
            aspectRatio={photoRenderOptions?.aspectRatio}
            blocking={index < 10}
            downloadPhoto={downloadPhoto}
            group={group}
            href={navigationContext.prepare(getViewRoute(group, "all"))}
            key={id}
            navigationState={{ feedId, photos: group }}
            padding={padding}
            photo={item}
            rect={rect}
            selection={selection}
            setCallout={setCallout}
          />
        );

        return element;
      },
      renderPlaceholder: renderNameplatePlaceholder,
      zoomLevel: mobileLayout ? minZoomLevel : zoomLevel
    };

    function renderSectionHeader(photos: IPhoto[], rect: IRectangle): React.ReactElement {
      return <PhotoHeader key={`header-${photos[0].id}`} photos={photos} rect={rect} selection={selection} />;
    }

    // If we are showing headers we will supply the section and header function.
    const sectionProperties = showHeaders ? { isSameSection: isSameDay, renderSectionHeader } : undefined;
    const padding: ITilePadding | undefined = showFilenames ? nameTilePadding : undefined;

    switch (layoutAlgorithm) {
      case "grid":
        return gridLayout<IPhoto, IPhotoRenderOptions>({
          ...layoutOptions,
          ...sectionProperties,
          padding,
          renderOptions: { aspectRatio: "1" }
        });

      case "river":
        return riverLayout<IPhoto, IPhotoRenderOptions>({ ...layoutOptions, ...sectionProperties, padding });

      // Default view is the masonry layout.
      case "masonry":
      default:
        return masonryLayout<IPhoto, IPhotoRenderOptions>(layoutOptions);
    }
  }, [
    autoGroupPhotos,
    feedId,
    layoutAlgorithm,
    mobileLayout,
    navigationContext,
    selection,
    setCallout,
    showCompactLayout,
    showFilenames,
    showHeaders,
    zoomLevel
  ]);

  return (
    <div
      className="all-photos-view standard-view relative flex-column flex-grow overflow-hidden depth-8"
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={(event) => {
        if (!event.defaultPrevented && selection.selectedCount) {
          if (event.key === "Escape") {
            eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", mode: "keyboard", name: "clearSelection" });
            selection.clear();
            event.preventDefault();
          } else if (event.key === "Delete") {
            eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", mode: "keyboard", name: "clickDelete" });
            setShowDeleteDialog(true);
            event.preventDefault();
          }
        }
      }}
    >
      <PageCommandbar
        defaultElementIds={{
          selectionActive: sessionContext.accountType !== "business" || selection.selectedCount === 1 ? "share" : "download",
          selectionInactive: !sessionContext.embedded ? "gallery" : mobileLayout ? "more" : "layout-dropdown"
        }}
        selection={selection}
      >
        {(selection) => (
          <>
            {selection.size === 0 ? (
              <Pivots activeId="gallery" className="flex-grow" />
            ) : (
              <ResponsiveLayout
                containerElement={commandElement}
                key="command-layout"
                responsiveSelectors={[
                  getSafeIdSelector("delete"),
                  getSafeIdSelector("download"),
                  getSafeIdSelector("add-to-album"),
                  getSafeIdSelector("share")
                ]}
              >
                {({ responsiveIndex }) => (
                  <div
                    className={css("flex-row flex-align-center flex-grow overflow-hidden", mobileLayout && "rhythm-horizontal-4")}
                    ref={commandElement}
                    role="group"
                  >
                    {(sessionContext.accountType !== "business" || selection.size === 1) && (
                      <Button
                        ariaLabel={ShareButton}
                        disabled={sessionContext.migrated && selection.size > 1}
                        iconProps={{ render: (className: string) => <Icons.Share className={className} /> }}
                        id="share"
                        onClick={commandShare}
                        role="menuitem"
                        subtle={true}
                        text={!mobileLayout ? ShareButton : undefined}
                      />
                    )}
                    {!sessionContext.embedded && showAlbums && (
                      <Button
                        ariaLabel={AddToAlbumButton}
                        iconProps={{ render: (className: string) => <Icons.AddPhotos className={className} /> }}
                        id="add-to-album"
                        onClick={commandAddToAlbum}
                        role="menuitem"
                        subtle={true}
                        text={!mobileLayout ? AddToAlbumButton : undefined}
                      />
                    )}
                    <Button
                      ariaLabel={DownloadButton}
                      iconProps={{ render: (className: string) => <Icons.Download className={className} /> }}
                      id="download"
                      onClick={commandDownload}
                      role="menuitem"
                      subtle={true}
                      text={!mobileLayout ? DownloadButton : undefined}
                    />
                    <Button
                      ariaLabel={DeleteButton}
                      iconProps={{ render: (className: string) => <Icons.Delete className={className} /> }}
                      id="delete"
                      onClick={commandDelete}
                      role="menuitem"
                      subtle={true}
                      text={!mobileLayout ? DeleteButton : undefined}
                    />
                    <Observer values={{ image: backgroundContext.getBackground("shell") }}>
                      {({ image }) => {
                        return backgroundImage ? (
                          <Button
                            ariaLabel={image ? ClearBackgroundButton : SetBackgroundButton}
                            disabled={selection.size > 1}
                            iconProps={{ render: (className: string) => <Icons.AddPhotos className={className} /> }}
                            id="background"
                            onClick={(event) => {
                              if (image) {
                                settingsContext.mergeSetting<UXSetting>("uxSetting", { backgroundImage: undefined });
                                backgroundContext.setBackground("shell", undefined);
                              } else {
                                const photo = Array.from(selection).map((id) => getItem(id)!)[0];

                                if (photo) {
                                  // Save the background image details into the user settings.
                                  settingsContext.mergeSetting<UXSetting>("uxSetting", {
                                    backgroundImage: { dimensions: { height: photo.dimensions.height, width: photo.dimensions.width }, id: photo.id }
                                  });

                                  // Update the view with the details about this photo.
                                  backgroundContext.setBackground("shell", { photo });
                                }
                              }

                              event.preventDefault();
                            }}
                            subtle={true}
                            text={!mobileLayout ? (image ? ClearBackgroundButton : SetBackgroundButton) : undefined}
                          />
                        ) : null;
                      }}
                    </Observer>
                    <MenuButton
                      ariaLabel={MoreButton}
                      className={css(responsiveIndex < 3 && "flex-noshrink margin-left-8", responsiveIndex === -1 && "hidden")}
                      contextualMenuProps={{
                        anchorOffset: { horizontal: 0, vertical: 4 },
                        menuProps: {
                          className: "compact-menu",
                          id: "overflow-menu",
                          items: [
                            {
                              id: "delete",
                              iconProps: { render: (className: string) => <Icons.Delete className={className} /> },
                              onActivate: commandDelete,
                              text: DeleteButton
                            },
                            {
                              disabled: selection.size > 1,
                              id: "download",
                              iconProps: { render: (className: string) => <Icons.Download className={className} /> },
                              onActivate: commandDownload,
                              text: DownloadButton
                            },
                            ...(!sessionContext.embedded && showAlbums
                              ? [
                                  {
                                    id: "add-to-album",
                                    iconProps: { render: (className: string) => <Icons.AddPhotos className={className} /> },
                                    onActivate: commandAddToAlbum,
                                    text: AddToAlbumButton
                                  }
                                ]
                              : []),
                            ...(sessionContext.accountType !== "business" || selection.size === 1
                              ? [
                                  {
                                    id: "share",
                                    iconProps: { render: (className: string) => <Icons.Share className={className} /> },
                                    onActivate: commandShare,
                                    text: ShareButton
                                  }
                                ]
                              : [])
                          ].slice(0, responsiveIndex + 1)
                        }
                      }}
                      hideDropdownIcon={true}
                      iconProps={{ iconName: "MoreVertical" }}
                      role="menuitem"
                      subtle={true}
                    />
                    <div className="flex-row flex-grow flex-justify-end flex-noshrink margin-left-8">
                      <Button
                        ariaLabel={`${ClearSelectionButton}, ${format(SelectedItems, { selectionCount: selection!.size })}`}
                        className="outline-button rounded-button"
                        iconProps={{ iconName: "Clear", size: IconSize.small }}
                        id="selection"
                        onClick={commandClearSelection}
                        role="menuitem"
                        subtle={true}
                        text={mobileLayout ? selection!.size.toString() : format(SelectedItems, { selectionCount: selection!.size })}
                      />
                    </div>
                  </div>
                )}
              </ResponsiveLayout>
            )}
            {mobileLayout ? (
              <MenuButton
                ariaLabel={MoreButton}
                className="margin-left-8"
                contextualMenuProps={{
                  anchorOffset: { horizontal: 0, vertical: 4 },
                  menuProps: {
                    className: "compact-menu status-menu",
                    id: "mobile-menu",
                    items: mobileOverflowMenuItems
                  }
                }}
                hideDropdownIcon={true}
                iconProps={{ iconName: "MoreVertical" }}
                id="more"
                onClick={() => {
                  if (mobileOverflowMenuItems.length === 0) {
                    mobileOverflowMenuItems.push(
                      getLayoutMenuItems(eventContext, sessionContext, featureContext, layoutAlgorithm, selectLayout, true)
                    );

                    // @TODO (ODB): Hide the filter by Special folder with business users for now
                    if (sessionContext.accountType !== "business") {
                      mobileOverflowMenuItems.push(getFilterMenuItems(sessionContext.driveId, filter.filterFolder, selectFilter, _getSpecialFolder));
                    }
                  }
                }}
                subtle={true}
              />
            ) : (
              <div className="flex-row flex-align-center flex-noshrink margin-left-12" role="group">
                <div className="flex-row rhythm-horizontal-8">
                  {
                    // @TODO (ODB): Hide the filter by Special folder with business users for now
                    sessionContext.accountType !== "business" && (
                      <FilterMenu getSpecialFolder={_getSpecialFolder} filterFolder={filter.filterFolder} selectFilter={selectFilter} />
                    )
                  }
                  <LayoutMenu layoutAlgorithm={layoutAlgorithm} selectAlgorithm={selectLayout} />
                  <CompactButton />
                </div>
                <div aria-hidden={true} className="separator-line-right margin-horizontal-16 margin-vertical-4" id={getSafeId("separator")} />
                {desktopLayout && <Zoom setZoomLevel={zoom} zoomLevel={zoomLevel} />}
                {tabletLayout && (
                  <Observer values={{ showZoomPopup }}>
                    {({ showZoomPopup }) => (
                      <div className="flex-row flex-center" ref={zoomElement}>
                        <Button
                          ariaLabel={ZoomControls}
                          iconProps={{
                            render: (className?: string) => <Icons.Zoom className={className} />
                          }}
                          id="zoom"
                          onClick={() => {
                            eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "openZoomPopup" });
                            setShowZoomPopup(!showZoomPopup);
                          }}
                          subtle={!showZoomPopup}
                        />
                      </div>
                    )}
                  </Observer>
                )}
              </div>
            )}
          </>
        )}
      </PageCommandbar>
      <div className="relative flex-row flex-grow overflow-hidden">
        <div
          aria-label={ForYouHeading}
          className="flex-column flex-grow overflow-x-hidden overflow-y-auto scrollbar-hidden"
          onScroll={(event) => {
            // Show and setup a timeout to hide the scrubber when scrolling stops.
            setScrubberTimeout(() => setShowScrubberContents(false), 1000);
            setShowScrubberContents(true);
          }}
          onWheel={(event) => {
            setTelemetryTimeout(() => {
              eventContext.dispatchEvent("telemetryAvailable", {
                action: "userAction",
                name: event.deltaY > 0 ? "scrollWheelDown" : "scrollWheelUp"
              });
            }, 250);
          }}
          ref={scrollingElement}
          role="complementary"
        >
          <Scrubber
            endDate={oldestPhoto}
            onTargetDate={(targetDate) => {
              const _layout = layout.current;
              const _layoutElement = layoutElement.current;
              const _scrollingElement = scrollingElement.current;

              // Determine if the targetDate is currently loaded and scroll to it.
              if (_scrollingElement && _layoutElement && _layout) {
                const layoutOffset =
                  _layoutElement.getBoundingClientRect().top - _scrollingElement.getBoundingClientRect().top + _scrollingElement.scrollTop;

                // Get the set of tiles that are currently laid out
                const tiles = _layout.getAllTiles();

                if (tiles.length && getPhotoTakenDate(tiles[0].item) >= targetDate && getPhotoTakenDate(tiles[tiles.length - 1].item) <= targetDate) {
                  const targetTime = targetDate.getTime();

                  // Use a binary search to find the closest tile to the target date
                  // and center that in the viewport.
                  const targetTile =
                    tiles[
                      binarySearch(tiles, targetTime, (tile: ITileDetails<IPhoto>, targetTime: number) => {
                        const photoTakenTime = getPhotoTakenDate(tile.item).getTime();

                        // NOTE: We return the opposite since we are sorted descending.
                        return photoTakenTime === targetTime ? 0 : photoTakenTime < targetTime ? -1 : 1;
                      })
                    ];

                  // NOTE: By scrolling the layout before it renders the tiles for that area
                  // it will cause a minor flash of empty background before rendering.
                  _scrollingElement.scrollTop = targetTile.rect.top + layoutOffset;
                } else {
                  // If we are going to the top of the scrubber scroll to the top.
                  if (targetDate === newestPhoto.value) {
                    _scrollingElement.scrollTop = 0;
                  }

                  // The targetDate is
                  setTargetDate(
                    targetDate !== newestPhoto.value ? new Date(targetDate.getTime() - targetDate.getTimezoneOffset() * 60 * 1000 + 1000) : undefined
                  );
                }
              }
            }}
            onWheel={(event) => {
              // This applies the wheel event from the scrubber to the underlying
              // scrolling element. This is not naturally applied since it is
              // absolutely positioned outside the scrolling element.
              if (scrollingElement.current) {
                scrollingElement.current.scrollTop += event.deltaY;
              }
            }}
            showContents={showScrubberContents}
            startDate={newestPhoto}
            viewportRange={dateRange}
          />
          {sessionContext.accountType === "consumer" && <NotificationGroup className={css("padding-top-16", horizontalSpacing)} />}
          {!sessionContext.embedded ? (
            <>
              <div
                className={css(
                  "flex-row flex-align-center flex-justify-between",
                  horizontalSpacing,
                  mobileLayout ? "padding-top-24" : "padding-top-32"
                )}
              >
                <span aria-level={1} className="title-s" role="heading">
                  {ForYouHeading}
                </span>
                <Observer values={{ carouselCount, carouselIndex, carouselItems }}>
                  {({ carouselCount, carouselIndex }) => (
                    <div className="flex-row flex-align-center">
                      <Button
                        ariaLabel={ViewPreviousTiles}
                        disabled={carouselIndex === 0}
                        iconProps={{ iconName: "ChevronLeft", size: IconSize.small }}
                        onClick={() => {
                          eventContext.dispatchEvent("telemetryAvailable", {
                            action: "userAction",
                            name: "navigateCarouselTiles",
                            value: "previous"
                          });

                          setCarouselIndex(carouselIndex - 1);
                        }}
                        subtle={true}
                      />
                      <Button
                        ariaLabel={ViewNextTiles}
                        disabled={carouselIndex + carouselCount === carouselItems.length}
                        iconProps={{ iconName: "ChevronRight", size: IconSize.small }}
                        onClick={() => {
                          eventContext.dispatchEvent("telemetryAvailable", {
                            action: "userAction",
                            name: "navigateCarouselTiles",
                            value: "next"
                          });

                          setCarouselIndex(carouselIndex + 1);
                        }}
                        subtle={true}
                      />
                    </div>
                  )}
                </Observer>
              </div>
              <Carousel
                carouselIndex={carouselIndex}
                className={css("padding-vertical-20", horizontalSpacing)}
                items={carouselItems}
                setCarouselIndex={setCarouselIndex}
                viewUpdated={setCarouselCount}
              />
            </>
          ) : null}
          {!showHeaders ? (
            <Observer values={{ dateRange, showGalleryHeaderBackground }}>
              {({ dateRange, showGalleryHeaderBackground }) => (
                <span
                  aria-level={sessionContext.embedded ? 2 : 1}
                  aria-label={AllPhotosTitle}
                  className={css(
                    "title-s padding-vertical-20",
                    horizontalSpacing,
                    dateRange && "photo-sticky-header",
                    dateRange && showGalleryHeaderBackground && "sticky-background"
                  )}
                  ref={galleryHeaderElement}
                  role="heading"
                >
                  {dateRange ? <DateRange dateRange={dateRange} /> : GalleryHeading}
                </span>
              )}
            </Observer>
          ) : (
            <span aria-level={sessionContext.embedded ? 2 : 1} className={css("title-s padding-vertical-20", horizontalSpacing)} role="heading">
              {AllPhotosTitle}
            </span>
          )}
          <div className={css("relative flex-column flex-grow flex-noshrink margin-bottom-16", horizontalSpacing)} ref={layoutElement} role="main">
            <Scenario
              complete={() => initialized}
              scenarioName="allPhotosLayout"
              properties={{ autoGroupPhotos, layoutAlgorithm, showCompactLayout, mobileLayout, zoomLevel }}
            >
              <Layout
                ariaLabel={PhotosList}
                componentRef={layout}
                itemFeed={photoFeed}
                layoutFunction={layoutFunction}
                selection={selection}
                scrollingElement={scrollingElement}
                viewUpdated={(renderedElements) => {
                  if (renderedElements.length && !initialized && targetDate) {
                    const _scrollingElement = scrollingElement.current;
                    const _layoutElement = layoutElement.current;

                    if (_scrollingElement && _layoutElement) {
                      const layoutOffset =
                        _layoutElement.getBoundingClientRect().top -
                        _scrollingElement.getBoundingClientRect().top +
                        _scrollingElement.scrollTop -
                        (showCompactLayout ? compactPhotoSpacing : spaciousPhotoSpacing) / 2;

                      _scrollingElement.scrollTop = layoutOffset;
                    }
                  }

                  const tiles = layout.current!.getVisibleTiles();
                  if (tiles.length) {
                    setDateRange({ endDate: getPhotoTakenDate(tiles[tiles.length - 1].item), startDate: getPhotoTakenDate(tiles[0].item) });
                  }
                }}
              />
              <Observer values={{ callout }}>{({ callout }) => callout}</Observer>
            </Scenario>
          </div>
        </div>
      </div>
      {initialized && items.length === 0 && (
        <IllustrationView
          illustration={<EmptyPhoto className="margin-20" />}
          title={EmptyPhotoTitle}
          subtitle={
            sessionContext.accountType === "business"
              ? EmptyPhotoAddNewSubTitle
              : formatComponent(EmptyPhotoSubTitle, (content: string) => (
                  <Link className="focus-treatment" href="https://onedrive.live.com/" key="upload-link">
                    {content}
                  </Link>
                ))
          }
        />
      )}
      <Observer values={{ showZoomPopup }}>
        {({ showZoomPopup }) =>
          showZoomPopup ? (
            <Callout
              anchorElement={zoomElement.current!}
              anchorOffset={{ horizontal: 0, vertical: 4 }}
              anchorOrigin={{ horizontal: Location.end, vertical: Location.end }}
              calloutOrigin={{ horizontal: Location.end, vertical: Location.start }}
              className="depth-16"
              contentClassName="padding-8 rounded-4"
              escDismiss={true}
              focuszoneProps={{
                circularNavigation: true,
                focusOnMount: true,
                handleTabKey: true,
                includeDefaults: true
              }}
              onDismiss={() => setShowZoomPopup(false)}
            >
              <Zoom setZoomLevel={zoom} zoomLevel={zoomLevel} />
            </Callout>
          ) : null
        }
      </Observer>
      <Observer values={{ showAddToAlbumDialog }}>
        {({ showAddToAlbumDialog }) =>
          showAddToAlbumDialog ? (
            <React.Suspense fallback={null}>
              <Scenario scenarioName="addtoalbum">
                <AddToAlbumPanel
                  addToAlbum={addToAlbum}
                  createAlbum={() => setShowCreateAlbumDialog(true)}
                  downloadPhoto={downloadPhoto}
                  getAlbums={(fetch: (url: string, init?: RequestInit) => Promise<Response>, url?: string) => {
                    if (url) {
                      return albumApi.getAlbumsPage(fetch, url).then(filterAlbumsAuto);
                    } else {
                      return albumApi.getAlbums(fetch, sessionContext.driveId).then(filterAlbumsAuto);
                    }
                  }}
                  onDismiss={() => setShowAddToAlbumDialog(false)}
                  photos={Array.from(selection.value).map((id) => getItem(id)!)}
                />
              </Scenario>
            </React.Suspense>
          ) : null
        }
      </Observer>
      <Observer values={{ showCreateAlbumDialog }}>
        {({ showCreateAlbumDialog }) =>
          showCreateAlbumDialog ? (
            <React.Suspense fallback={null}>
              <Scenario scenarioName="createalbumdialog">
                <CreateAlbumDialog
                  createAlbum={createAlbum}
                  downloadPhoto={downloadPhoto}
                  getOldestPhoto={getOldestPhoto}
                  getPhotos={getPhotos}
                  getPhotosPage={getPhotosPage}
                  onDismiss={() => setShowCreateAlbumDialog(false)}
                  photos={Array.from(selection.value).map((id) => getItem(id)!)}
                />
              </Scenario>
            </React.Suspense>
          ) : null
        }
      </Observer>
      <Observer values={{ showShareDialog }}>
        {({ showShareDialog }) => {
          const ShareDialog = sessionContext.accountType === "business" ? ShareDialogODB : ShareDialogODC;
          return showShareDialog ? (
            <React.Suspense fallback={null}>
              <Scenario scenarioName="sharedialog">
                <ShareDialog
                  items={Array.from(selection.value).map((id) => getItem(id)!)}
                  onDismiss={() => {
                    setShowShareDialog(false);
                  }}
                />
              </Scenario>
            </React.Suspense>
          ) : null;
        }}
      </Observer>
      <Observer values={{ showDeleteDialog }}>
        {({ showDeleteDialog }) =>
          showDeleteDialog ? (
            <React.Suspense fallback={null}>
              <Scenario scenarioName="deletephotos">
                <DeletePhotoDialog
                  deletePhotos={deletePhotos}
                  duplicatePhotoParentName={getDuplicatedPhotosParent(selection, getItem)}
                  items={Array.from(selection.value).map((id) => getItem(id)!)}
                  onDismiss={() => {
                    setShowDeleteDialog(false);
                  }}
                  photoIds={getAllSelectedPhotos(selection, getItem).map((photo) => photo.id)}
                />
              </Scenario>
            </React.Suspense>
          ) : null
        }
      </Observer>
    </div>
  );

  function commandAddToAlbum(): void {
    eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "clickAddToAlbum" });
    setShowAddToAlbumDialog(true);
  }

  function commandClearSelection(): void {
    eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "clearSelection" });
    selection.clear();
  }

  function commandDelete(): void {
    eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "clickDelete" });
    setShowDeleteDialog(true);
  }

  function commandDownload(): void {
    eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "clickDownload" });
    downloadPhotos(
      eventContext,
      authorizationContext,
      embedContext,
      sessionContext,
      Array.from(selection.value, (photoId) => getItem(photoId)!),
      enableServerZipDownload
    ).catch(handleRejection);
  }

  function commandShare(): void {
    eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "clickShare" });
    setShowShareDialog(true);
  }

  function selectFilter(filterFolder: FilterFolder, folderId: string): void {
    eventContext.dispatchEvent("telemetryAvailable", {
      action: "userAction",
      name: "selectFilter",
      value: filterFolder
    });

    // Cause the UX to center on the current tile after the next update.
    centerOnItem();

    // Make sure the setting is updated when in mobile mode.
    setUxSetting({ galleryFilter: { filterFolder, folderId } });

    // Save the updated layout preference and update the UX.
    setFilter({ filterFolder, folderId });
  }

  function selectLayout(layoutAlgorithm: LayoutAlgorithm): void {
    eventContext.dispatchEvent("telemetryAvailable", {
      action: "userAction",
      name: "selectLayout",
      value: layoutAlgorithm
    });

    // Cause the UX to center on the current tile after the next update.
    centerOnItem();

    // Save the updated layout preference and update the UX.
    setUxSetting({ layoutAlgorithm });
    setLayoutAlgorithm(layoutAlgorithm);
  }
}

export default AllPhotosView;
