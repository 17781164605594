import React from "react";
import { EventContext } from "../../contexts/event";
import { FeatureContext } from "../../contexts/feature";
import { ScenarioContext } from "../../contexts/scenario";
import { useEventListener } from "../../hooks/uselistener";
import { debugBreak } from "../../utilities/browser";
import { noop } from "../../utilities/func";
import { defer } from "../../utilities/promise";
import { CompleteCallback, getScenarioEvent, IScenario, IScenarioDetails, IScenarioOptions, startScenario } from "../../utilities/scenario";

export interface IScenarioProps extends Omit<IScenarioOptions, "scenarioType"> {
  /**
   * A delegate that is called when the scenario is complete. The callback
   * is given the completed scenario which contains the result of the
   * scenario. The completeCallback can return a properties object that can
   * contain properties that should be added to the resulting scenario.
   *
   * @default undefined
   */
  completeCallback?: CompleteCallback<unknown>;

  /**
   * The caller can supply a function used to determine if the scenario is
   * complete yet or not. The complete function is called after each render
   * pass until it returns true. If the component umounts before completing
   * the scenario will be completed automatically.
   */
  complete?: () => boolean;
}

const defaultWaitForPaint = process.env.NODE_ENV !== "test";

export function Scenario(props: IScenarioProps & { children?: React.ReactNode }): React.ReactElement {
  const { completeCallback, properties, scenarioName, startTime, waitForPaint = defaultWaitForPaint } = props;

  if (properties && properties["pathname"]) {
    // remove the file extension if it exists for privacyguard
    properties["pathname"].replace(/\.[^.]*$/, "");
  }

  const eventContext = React.useContext(EventContext);
  const featureContext = React.useContext(FeatureContext);
  const scenarioContext = React.useContext(ScenarioContext);

  // Track whether or not the scenario has been completed, and if it has we dont
  // need to reevaluate it again.
  const completed = React.useRef(false);
  const inactiveStartTime = React.useRef<undefined | number>(document.visibilityState === "hidden" ? Date.now() : undefined);
  const inactiveTime = React.useRef(0);

  // Create a promise that resolves when the scenario completes mounting.
  const [{ reject, resolve, promise }] = React.useState(() => defer<void>());

  const [currentScenario] = React.useState(() => {
    const parentScenario = scenarioContext.getScenario();
    let currentScenario: IScenario<unknown>;

    if (parentScenario) {
      currentScenario = parentScenario.waitUntil(
        promise,
        { properties, scenarioName, scenarioType: "componentRender", startTime, waitForPaint },
        completeCallback
      );
    } else {
      currentScenario = startScenario(
        promise,
        { properties, scenarioName, scenarioType: "componentRender", startTime, waitForPaint },
        (scenarioDetails: IScenarioDetails, result: PromiseSettledResult<unknown>) => {
          if (inactiveTime.current > 0) {
            scenarioDetails.inactiveTime = inactiveTime.current;

            // excludeTDC: the reason to exclude from total duration calculation
            currentScenario.log({ excludeTDC: "runInBackground" });
          }

          if (completeCallback) {
            completeCallback(scenarioDetails, result);
          }

          // Send out the scenarioComplete and telemetryAvailable events.
          const scenarioEvent = getScenarioEvent("scenarioComplete", scenarioDetails);
          eventContext.dispatchEvent("scenarioComplete", scenarioEvent);
          eventContext.dispatchEvent("telemetryAvailable", scenarioEvent);

          // Now that we have recorded the scenario, check if the diagnostics
          // system wants us to break into the debugger. This is vrey useful for
          // ensuring the recorded time is showing what we expect. Don't break
          // on child scenario's. Wait for the top level scenario to complete.
          if (featureContext.featureEnabled("breakOnTTI").value) {
            debugBreak();
          }
        }
      );

      // Note the beginning of a new scenario starting, we only fire on the root scenario.
      eventContext.dispatchEvent("scenarioStart", currentScenario);
    }

    // If the scenario fails we will ignore it, telemetry will capture the failure.
    currentScenario.scenarioPromise.catch(noop);

    return currentScenario;
  });

  useEventListener(document, "visibilitychange", React.useCallback(handleVisibilityChange, []));

  // UseEffect is used to trigger completion when the component that starts the
  // scenario completes mounting.
  React.useEffect(() => {
    if (!completed.current) {
      if (!props.complete || props.complete()) {
        completed.current = true;
        resolve();
      }
    }
  });

  // If the component unmounts before completing we will reject the scenario.
  React.useEffect(() => {
    return () => {
      reject({
        isCanceled: true,
        reason: { unmount: true },
        tag: "Scenario"
      });
    };
  }, [reject]);

  return (
    <ScenarioContext.Provider
      value={{
        getScenario: () => {
          if (currentScenario.status() === "working") {
            return currentScenario;
          }
        }
      }}
    >
      {props.children}
    </ScenarioContext.Provider>
  );

  function handleVisibilityChange(): void {
    if (document.visibilityState === "hidden") {
      inactiveStartTime.current = Date.now();
    } else if (inactiveStartTime.current) {
      inactiveTime.current += Date.now() - inactiveStartTime.current;
    }
  }
}
