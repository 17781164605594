import React from "react";

/**
 * Simple check used to determine if a navigation is using one of the meta forms.
 * Meta navigations are ones like open in New Window, New Tab, etc. These are
 * performed via middle click, Alt click, Ctrl click, Shift click, Meta click (Mac).
 *
 * @param event The keyboard or mouse click event that is triggering the navigation.
 * @returns true if this is a meta navigation, false otherwise.
 */
export function isMetaNavigation(event: KeyboardEvent | MouseEvent | React.KeyboardEvent | React.MouseEvent): boolean {
  return event.type === "click" && ((event as MouseEvent).buttons === 1 || event.altKey || event.ctrlKey || event.metaKey || event.shiftKey);
}
