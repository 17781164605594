import React from "react";
import { downloadPhoto } from "../api/photo";
import { ICarouselItem } from "../components/carousel/carousel";
import { IPhotoCarouselCard, PhotoCarouselCard } from "../components/carousel/carouselcard";
import { getPhotoContent } from "../hooks/usephotocontent";
import { ILocation, IPhoto } from "../types/photo";
import { formatURL } from "../../common/utilities/format";

const { RecentPlacesSubtitle } = window.Resources.Common;

export const recentPlacesMinimumAmountOfPhotos = 5;
export const recentPlacesMinimumDistanceInMiles = 10;
export const recentPlacesMaximumCardCount = 3;

const degreesToRadians = Math.PI / 180;
const earthRadiusInMiles = 3959;

export function createRecentPlacesCards(photos: IPhoto[]): ICarouselItem<IPhotoCarouselCard>[] {
  if (!photos?.length) {
    return [];
  }

  const recentPlacesCards: ICarouselItem<IPhotoCarouselCard>[] = [];

  const recentPlaces = [];
  const mapNameToPlace: Map<string, IPhoto[]> = new Map();
  let cardCount = 0;
  // to keep track of locations seen on photos
  // to keep track of number of photos for a given location
  for (let i = 0; i < photos.length; i++) {
    if (cardCount === recentPlacesMaximumCardCount) {
      break;
    }

    const photo = photos[i];
    const photoLocation = hasLocationData(photo) ? photo.location : undefined;
    if (photoLocation) {
      const displayName = photoLocation.displayName!;
      const placeEntries = mapNameToPlace.get(displayName) ?? [];
      const newSet = [...placeEntries, photo];
      cardCount += newSet.length === recentPlacesMinimumAmountOfPhotos ? 1 : 0;
      mapNameToPlace.set(displayName, newSet);
    }
  }

  const entries = Array.from(mapNameToPlace.values());
  const minEntries = entries.filter((entry) => {
    return entry.length >= recentPlacesMinimumAmountOfPhotos;
  });

  // pick the first photo per each location for distance calculation
  const [primaryPlace, ...places] = minEntries.map((entryList) => entryList[0]);
  if (primaryPlace) {
    const primaryLocation = primaryPlace.location!;
    for (const secondaryPlace of places) {
      if (
        secondaryPlace &&
        hasLatLng(primaryLocation) &&
        hasLatLng(secondaryPlace.location!) &&
        isAtLeastRequiredDistanceApart(primaryLocation, secondaryPlace.location!)
      ) {
        recentPlaces.push(secondaryPlace);
      }
    }

    recentPlaces.unshift(primaryPlace);
  }

  // see documentation/client/componentspecific/foryoucarousel.md for ordering logic
  let order = 70;
  recentPlaces.forEach((place: IPhoto, index: number) => {
    const placeName = getPlaceDisplayName(place.location!);
    const address = place.location?.address;

    const params: { [parameterName: string]: string } = {};
    if (address?.countryOrRegion) {
      params.country = address?.countryOrRegion;
    }
    if (address?.state) {
      params.state = address?.state;
    }
    if (address?.city) {
      params.city = address?.city;
    }

    const carouselItem: ICarouselItem<IPhotoCarouselCard> = {
      details: {
        getPhotoContent: getPhotoContent(downloadPhoto),
        href: formatURL("/recent?{{parameters}}", { parameters: new URLSearchParams(params) }),
        photo: place,
        title: placeName,
        subtitle: RecentPlacesSubtitle
      },
      id: place.id,
      order: order + index,
      render: (cardProps) => <PhotoCarouselCard key={`recentPlaces-${index}`} {...cardProps} />
    };

    recentPlacesCards.push(carouselItem);
  });

  return recentPlacesCards;
}

function hasLatLng(location: ILocation): boolean {
  return Boolean(location.latitude && location.longitude);
}

function hasLocationData(photo: IPhoto): boolean {
  return Boolean(photo.location && photo.location.displayName && photo.location.latitude && photo.location.longitude);
}

/**
 * Funtion that determines if two locations are min distance apart to be considered for the recent places tile
 *
 * @param primaryLocation first location with latitude and longitude properties
 * @param secondaryLocation second location with latitude and longitude properties
 * @returns boolean
 */
function isAtLeastRequiredDistanceApart(primaryLocation: ILocation, secondaryLocation: ILocation): boolean {
  const dLatitude = (secondaryLocation.latitude - primaryLocation.latitude) * degreesToRadians;
  const dLongitude = (secondaryLocation.longitude - primaryLocation.longitude) * degreesToRadians;

  const haversine =
    Math.pow(Math.sin(dLatitude / 2), 2) +
    Math.pow(Math.sin(dLongitude / 2), 2) *
      Math.cos(primaryLocation.latitude * degreesToRadians) *
      Math.cos(secondaryLocation.latitude * degreesToRadians);

  const distance = Math.asin(Math.sqrt(haversine)) * earthRadiusInMiles * 2;
  return distance >= recentPlacesMinimumDistanceInMiles;
}

/**
 * @param place Photo with location included
 * @returns name for the places tile
 */
export function getPlaceDisplayName(location: ILocation): string {
  const address = location.address;
  const displayName = location.displayName || "";

  if (!address) {
    return displayName;
  }

  const countryOrRegion = address.countryOrRegion;
  const state = address.state;
  const city = address.city ?? "";

  const availablePlacesNames: string[] = [];
  if (city) {
    availablePlacesNames.push(city);
  }
  if (state) {
    availablePlacesNames.push(state);
  }
  if (countryOrRegion) {
    availablePlacesNames.push(countryOrRegion);
  }

  return availablePlacesNames.length > 1 ? availablePlacesNames[0] + ", " + availablePlacesNames[availablePlacesNames.length - 1] : displayName;
}
