import { IObservableValue, ObservableValue } from "azure-devops-ui/Core/Observable";
import React from "react";
import { deleteCookie, getCookies } from "../../common/utilities/browser";
import { IApiOrigin, IApiVersion, IDomainSettings, apiOriginODC, apiVersionODC, domainSettings } from "../api/util";
import { IAccount } from "../types/account";
import { IMessage } from "../types/message";
import { IGraphProfile } from "../types/profile";
import { AuthScopeOneDrive } from "./authorization";

export const idTokenName = "IdToken";
export const refreshTokenName = "RefreshToken";

export const msaTenantId = "9188040d-6c67-4c5b-b112-36a304b66dad";

/**
 * The session information is data that comes from the server when the page is
 * generated. This data is merged with run-time state to make up the
 * SessionContext.
 */
export interface ISessionInformation {
  accountType?: "business" | "consumer";
  ariaToken?: string;
  cdnDomain?: string;
  clientId: string;
  clientVersion: string;
  dogfoodUser: boolean;
  driveId?: string;
  embedded?: boolean;
  environment: string;
  experiments: { [name: string]: any };
  locale?: string;
  loginAuthority: string;
  message?: IMessage;
  pathname?: string;
  ramps: { [name: string]: boolean };
  redirectUri: string;
  ring: string;
  serverVersion: string;
  tenantId?: string;
  tenantType?: "msa" | "aad";
  userId?: string;
  userProfile?: IUserProfile;
  telemetryEndpoint?: string;
}

/**
 * The session context contains additional information which has been resolved
 * after parsing the session info and other run-time derived information
 * computed when the application is mounted
 */
export interface ISessionContext extends ISessionInformation {
  accountDetails: IObservableValue<IAccount | undefined>;
  accountType?: "business" | "consumer";
  apiOrigin: IApiOrigin;
  apiVersion: IApiVersion;
  ariaToken?: string;
  authenticated: () => boolean;
  domainSettings: IDomainSettings;
  driveId: string;
  getPage: () => { current: string; previous: string };
  graphProfile: IObservableValue<IGraphProfile | undefined>;
  locale: string;
  migrated?: boolean;
  profileUrl: IObservableValue<string>;
  sandboxEnv?: boolean;
  session: string;
  setCurrentPage: (page: string) => void;
  standalone: boolean | undefined;
}

export interface IUserProfile {
  family_name?: string;
  given_name?: string;
  name: string;
  locale: string;
  oid: string;
  preferred_username: string;
}

/**
 * Default session information. Provides base set of values when the server does not provide them
 * (e.g. in dev mode)
 */
export const defaultSessionInfo: ISessionInformation = {
  ariaToken: "b099dca11fed49d7994ca255014628ad-64f54736-b2a7-44a6-9c34-4348f6d52011-7189",
  cdnDomain: "res.cdn.office.net",
  clientId: "cac44c57-87e7-4c86-88e2-e2ea658ecb30",
  clientVersion: "dev",
  dogfoodUser: true,
  environment: "development",
  experiments: {},
  loginAuthority: "login.microsoftonline.com",
  ramps: {},
  redirectUri: "http://localhost:3000/auth/login",
  ring: "dev",
  serverVersion: "dev",
  telemetryEndpoint: "https://browser.events.data.microsoft.com/OneCollector/1.0/",
  tenantId: msaTenantId
};

const defaultSessionContext: ISessionContext = {
  ...defaultSessionInfo,
  accountDetails: new ObservableValue<IAccount | undefined>(undefined),
  apiOrigin: apiOriginODC,
  apiVersion: apiVersionODC,
  authenticated: () => false,
  domainSettings,
  driveId: "me",
  getPage: () => ({ current: "", previous: "" }),
  graphProfile: new ObservableValue<IGraphProfile | undefined>(undefined),
  locale: "en-US",
  profileUrl: new ObservableValue(""),
  session: "",
  setCurrentPage: () => {},
  standalone: false
};

/**
 * Creates a sign-in URI for the user to log in to the app when auth is required
 * @param sessionContext The session
 * @param site The site we're authenticating for
 * @param loginHint The account that we're hinting at logging in. Optional
 * @returns Sign in URI
 */
export function getSignInUri(sessionContext: ISessionContext, site: string, loginHint?: string): string {
  // Remove the login hint from the redirect URI, we will accept whatever user
  // ends up logging in. If we dont we could end up in a login loop.
  const currentParams = new URLSearchParams(window.location.search);
  currentParams.delete("login_hint");

  // We are using the state parameter as the target page to land on after the login.
  const currentParamsValue = currentParams.toString();
  const state = currentParamsValue ? `${window.location.pathname}?${currentParamsValue}` : window.location.pathname;

  const oneDriveAuthScope = sessionContext.sandboxEnv && sessionContext.migrated ? AuthScopeOneDrive.Sandbox : AuthScopeOneDrive.Default;

  // Build up the set of parameters we will pass to the login authority.
  const authorization: { [param: string]: string } = {
    client_id: sessionContext.clientId,
    nonce: `${site}.${sessionContext.session || "53bebd0c-4d8a-4448-9991-e0f9dbc4a83a"}`,
    redirect_uri: sessionContext.redirectUri,
    response_mode: "form_post",
    response_type: "code",
    scope: `${oneDriveAuthScope} Files.ReadWrite People.Read User.Read offline_access openid profile`,
    state
  };

  // Add the login hint if one was supplied
  if (loginHint) {
    authorization.login_hint = loginHint;
  }

  return new URL(
    `https://${sessionContext.loginAuthority}/consumers/oauth2/v2.0/authorize?${new URLSearchParams(authorization).toString()}`
  ).toString();
}

/**
 * Removes the cookies that are associated to the user to log them out
 */
export function clearUserCookies() {
  const cookies = getCookies();

  // Delete all accesstoken cookies.
  for (let cookieName in cookies) {
    if (cookieName.indexOf("AccessToken-") === 0) {
      deleteCookie(cookieName);
    }
  }

  deleteCookie(idTokenName);
  deleteCookie(refreshTokenName);
}

export const SessionContext = React.createContext<ISessionContext>(defaultSessionContext);
