import { IReadonlyObservableValue } from "azure-devops-ui/Core/Observable";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useObjectURL } from "../../common/hooks/useobjecturl";
import { useObservable } from "../../common/hooks/useobservable";
import { IDownloadOptions } from "../api/photo";
import { AuthorizationContext } from "../contexts/authorization";
import { SessionContext } from "../contexts/session";
import { WorkerContext } from "../contexts/worker";
import { useFetch } from "../hooks/usefetch";
import { IDimensions } from "../types/item";
import { getPhotoScale } from "../utilities/image";

const closeParan = /\)/g;
const openParan = /\(/g;

export interface IPhotoContent {
  status: IReadonlyObservableValue<"fulfilled" | "rejected" | undefined> | "fulfilled" | "rejected";
  url: IReadonlyObservableValue<string | undefined> | string | undefined;
}

export interface IPhotoContentOptions extends Omit<IDownloadOptions, "photoScale"> {
  /**
   * An optional filename to include in the URL for content when its supported.
   */
  filename?: string;

  /**
   * The scale of the photo to be retrieved.
   */
  photoScale?: PhotoContentScale;
}

/**
 * Set of valid scale types that can be requested when requesting the content
 * of the photo / video.
 */
export type PhotoContentScale = IDimensions | "full" | undefined;

/**
 * getPhotoContent is a wrapper around the usePhotoContent hook that allows the
 * caller to create a hook instance bound to the downloadPhoto method of their
 * choice.
 *
 * @param downloadPhoto Method that can be used to download the content of the
 * photo from its source.
 * @returns An instance of a React hook method that can be used to get the
 * content (URL and status) for a given photo.
 */
export function getPhotoContent(
  downloadPhoto: (
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    photoId: string,
    options?: IDownloadOptions
  ) => Promise<Blob>
): (photoId: string, options?: IPhotoContentOptions) => IPhotoContent {
  return _getPhotoContent;

  function _getPhotoContent(photoId: string, options?: IPhotoContentOptions): IPhotoContent {
    return usePhotoContent(photoId, downloadPhoto, options);
  }
}

/**
 * usePhotoContent is a React hook that can be used to retrieve a URL to a photo
 * via a number of potential access methods.
 *
 * Method one, a plain URL that references the photo, this requires the
 * vroomAuthorization service worker capability. This allows a plain photo url
 * to be used and the service worker will take care of authorization.
 *
 * Method two, the call with use the downloadPhoto method to retrieve the
 * contents of the photo and create a local photo using window.createObjectUrl.
 *
 * @param photoId The Id of the photo.
 * @param photoScale The scale that should be downloaded from the source.
 * @param downloadPhoto The method used to retrieve the photo from the source.
 * @param options Any options controlling how the content should be resolved.
 * @returns and instance of IPhotoContent, depending on how the content is
 * being resolved this may be immediate or asynchronous.
 */
export function usePhotoContent(
  photoId: string,
  downloadPhoto: (
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    photoId: string,
    options?: IDownloadOptions
  ) => Promise<Blob>,
  options?: IPhotoContentOptions
): IPhotoContent {
  const authorizationContext = React.useContext(AuthorizationContext);
  const sessionContext = React.useContext(SessionContext);
  const workerContext = React.useContext(WorkerContext);

  const { cacheToken = "", filename, photoScale } = options || {};

  const [observableStatus, setObservableStatus] = useObservable<"fulfilled" | "rejected" | undefined>(undefined);
  const [observableUrl, setObservableUrl] = useObservable<string | undefined>(undefined);

  // Note: We dont need to record the network as a second child, mark it non-blocking.
  const _downloadPhoto = useFetch(downloadPhoto, { blocking: false, name: "downloadPhoto" });

  // Use the hook to create and manage the object URL for this photo content.
  const { createObjectURL } = useObjectURL();

  // Convert the dimensions into the API scale value "c<width>x<height>"
  const downloadScale = photoScale === undefined || photoScale === "full" ? photoScale : getPhotoScale(photoScale.height, photoScale.width);

  // When the photo or size change we want to download a new version.
  return React.useMemo(() => {
    if (downloadScale) {
      // For migrated content and user, driveId cannot be always extracted from PhotoId.
      const driveId = sessionContext.driveId;
      let scale = downloadScale;

      // If our service worker is in place and supported vroomAuthentication we
      // will use vroom based URL's directly as the URL. Ensure fixes for
      // migrated users are available before depending on the service worker.
      if (
        workerContext.workerDetails.value.capabilities.indexOf("vroomAuthorization") >= 0 &&
        (!sessionContext.migrated || workerContext.workerDetails.value.capabilities.indexOf("vroomAuthorization2") >= 0) &&
        !authorizationContext.authkey &&
        !authorizationContext.badgerToken &&
        !sessionContext.embedded &&
        sessionContext.authenticated()
      ) {
        const { apiOrigin, apiVersion } = sessionContext;
        const params: { [parameterName: string]: string } = {};

        if (downloadScale !== "full") {
          params.prefer = "noredirect,closestavailablesize";
        }

        if (cacheToken) {
          params.cb = cacheToken;
        }

        // Create url search parameters from our list of parameters we are including.
        const searchParams = new URLSearchParams(params);

        // If the service worker supports extra headers we will add ours.
        if (workerContext.workerDetails.value.capabilities.indexOf("extraHeaders") >= 0) {
          searchParams.set("eh", `Scenario:${sessionContext.getPage().current}`);
        }

        return {
          status: "fulfilled",
          url:
            downloadScale !== "full"
              ? `${apiOrigin.vroomOrigin}/${
                  apiVersion.downloadVersion
                }/drives/${driveId}/items/${photoId}/thumbnails/0/${scale}/content${getContentSegment(filename, scale)}?${searchParams}`
              : `${apiOrigin.vroomOrigin}/${apiVersion.downloadVersion}/drives/${driveId}/items/${photoId}/content?${searchParams}`
        };
      }

      // If the service worker is not in place or vroomAuthentication is not supported
      // we will fetch the photo content using a foreground fetch call and creating
      // a local objectUrl with the content.
      else {
        _downloadPhoto(driveId, photoId, { cacheToken, photoScale: downloadScale })
          .then((content) => {
            unstable_batchedUpdates(() => {
              setObservableUrl(createObjectURL(content));
              setObservableStatus("fulfilled");
            });

            return content;
          })
          .catch(() => {
            unstable_batchedUpdates(() => {
              // When a photo fails to load we will use the placeholder URL.
              setObservableStatus("rejected");
            });
          });
      }
    }

    return { status: observableStatus, url: observableUrl };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheToken, downloadScale, photoId]);

  function getContentSegment(filename?: string, downloadScale?: string): string {
    if (!sessionContext.migrated && filename) {
      const extensionIndex = filename.lastIndexOf(".");
      if (extensionIndex >= 0) {
        filename = `${filename.substring(0, extensionIndex)}.${downloadScale}.${filename.substring(extensionIndex + 1)}`;
      }

      return `/${filename.replace(openParan, "[").replace(closeParan, "]")}`;
    }

    return "";
  }
}
