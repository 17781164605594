import { formatURL } from "../../common/utilities/format";
import { IItem, IItemPage } from "../types/item";
import { IAlbum, IAlbumODC, IModifyAlbumResponse, INetworkPhoto, IPhoto } from "../types/photo";
import { getItem } from "./item";
import { graphJSON, graphNoContent, graphRequest, graphResponse } from "./network";
import { defaultPhotoSelect } from "./photo";
import { preparePhoto } from "./util";

export interface IAlbumApi {
  addToAlbum(
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    albumId: string,
    photoIds: string[]
  ): Promise<IModifyAlbumResponse>;
  createAlbum(
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    albumName: string,
    photoIds?: string[]
  ): Promise<IAlbum>;
  deleteAlbum(fetch: (url: string, init?: RequestInit) => Promise<Response>, driveId: string, albumId: string): Promise<void>;
  getAlbum(
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    albumId: string,
    options?: IGetAlbumOptions
  ): Promise<IAlbum>;
  getAlbums(fetch: (url: string, init?: RequestInit) => Promise<Response>, driveId: string, options?: IGetAlbumsOptions): Promise<IItemPage<IAlbum>>;
  getAlbumsPage(fetch: (url: string, init?: RequestInit) => Promise<Response>, url: string): Promise<IItemPage<IAlbum>>;
  getAlbumPhotos(
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    albumId: string,
    options?: IGetAlbumPhotosOptions
  ): Promise<IItemPage<IPhoto>>;
  removeAlbumPhoto(
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    albumId: string,
    photoIds: string[]
  ): Promise<IModifyAlbumResponse>;
  setCoverPhoto(
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    albumId: string,
    coverSourceId: string
  ): Promise<void>;
  updateAlbum(fetch: (url: string, init?: RequestInit) => Promise<Response>, driveId: string, albumId: string, albumName: string): Promise<void>;
}

export interface IGetAlbumOptions {
  expand?: string[];
  select?: string;
}

export interface IGetAlbumsOptions {
  expand?: string[];
  orderBy?: "asc" | "desc";
  select?: string;
  top?: number;
  filter?: string;
}
export interface IGetAlbumPhotosOptions {
  expand?: string[];
  select?: string;
  top?: number;
  filter?: string;
}

const createAlbumApiCob = "{{vroomOrigin}}/_api/v2.1/drives/{{driveId}}/albums";
const getAlbumApiCob = "{{vroomOrigin}}/_api/v2.1/drives/{{driveId}}/albums/{{albumId}}?{{parameters}}";
const getAlbumPhotosApiCob = "{{vroomOrigin}}/_api/v2.1/drives/{{driveId}}/albums/{{albumId}}/children?{{parameters}}";
const getAlbumsApiCob = "{{vroomOrigin}}/_api/v2.1/drives/{{driveId}}/albums?{{parameters}}";
const manageItemsApiCob = "{{vroomOrigin}}/_api/v2.1/drives/{{driveId}}/albums/{{albumId}}/oneDrive.manageItems";
const updateAlbumApiCob = "{{vroomOrigin}}/_api/v2.1/drives/{{driveId}}/albums/{{albumId}}";

export const defaultAlbumSelectCob = "*,sharepointIds";

export const cobAlbumApi: IAlbumApi = {
  addToAlbum: (
    fetch: (url: string, init?: RequestInit | undefined) => Promise<Response>,
    driveId: string,
    albumId: string,
    photoIds: string[]
  ): Promise<IModifyAlbumResponse> => {
    return graphResponse<IModifyAlbumResponse>(
      fetch(formatURL(manageItemsApiCob, { driveId, albumId }), {
        body: JSON.stringify({
          value: photoIds.map((id) => {
            return {
              id: id
            };
          })
        }),
        headers: { "Content-Type": "application/json", prefer: "returnCopiedItems" },
        method: "POST"
      })
    );
  },
  createAlbum: (
    fetch: (url: string, init?: RequestInit | undefined) => Promise<Response>,
    driveId: string,
    albumName: string,
    photoIds?: string[] | undefined
  ): Promise<IAlbum> => {
    return graphResponse<IAlbum>(
      fetch(formatURL(createAlbumApiCob, { driveId }), {
        body: JSON.stringify({
          children: photoIds ? photoIds.map((photoId) => ({ id: photoId })) : [],
          name: albumName
        }),
        headers: { "Content-Type": "application/json" },
        method: "POST"
      })
    );
  },
  deleteAlbum: (fetch: (url: string, init?: RequestInit | undefined) => Promise<Response>, driveId: string, albumId: string): Promise<void> => {
    return graphResponse<void>(fetch(formatURL(updateAlbumApiCob, { driveId, albumId }), { method: "DELETE" }), graphNoContent);
  },
  getAlbum: (
    fetch: (url: string, init?: RequestInit | undefined) => Promise<Response>,
    driveId: string,
    albumId: string,
    options?: IGetAlbumOptions | undefined
  ): Promise<IAlbum> => {
    const { expand = ["thumbnails"], select = defaultAlbumSelectCob } = options || {};
    const params: { [parameterName: string]: string } = { select };

    if (expand && expand.length) {
      params.expand = `${expand.join(",")}`;
    }

    return graphResponse<IAlbum>(fetch(formatURL(getAlbumApiCob, { albumId, driveId, parameters: new URLSearchParams(params) })));
  },
  getAlbums: (
    fetch: (url: string, init?: RequestInit | undefined) => Promise<Response>,
    driveId: string,
    options?: IGetAlbumsOptions | undefined
  ): Promise<IItemPage<IAlbum>> => {
    const { expand = ["thumbnails"], orderBy = "desc", select = defaultAlbumSelectCob, top = 30 } = options || {};
    const params: { [parameterName: string]: string } = {
      orderby: `createdDateTime ${orderBy}`,
      select,
      top: `${top}`
    };

    if (expand && expand.length) {
      params.expand = `${expand.join(",")}`;
    }

    return graphResponse<IItemPage<IAlbum>>(fetch(formatURL(getAlbumsApiCob, { driveId, parameters: new URLSearchParams(params) })));
  },
  getAlbumsPage: (fetch: (url: string, init?: RequestInit | undefined) => Promise<Response>, url: string): Promise<IItemPage<IAlbum>> => {
    return graphRequest<IItemPage<IAlbum>>(fetch, url);
  },
  getAlbumPhotos: (
    fetch: (url: string, init?: RequestInit | undefined) => Promise<Response>,
    driveId: string,
    albumId: string,
    options?: IGetAlbumPhotosOptions | undefined
  ): Promise<IItemPage<IPhoto>> => {
    const { expand = ["tags"], select = defaultPhotoSelect, top = 100, filter = "photo ne null" } = options || {};
    const params: { [parameterName: string]: string } = { select, top: `${top}`, filter };

    if (expand && expand.length) {
      params.expand = `${expand.join(",")}`;
    }

    return graphResponse(fetch(formatURL(getAlbumPhotosApiCob, { driveId, albumId, parameters: new URLSearchParams(params) }))).then(
      (photoPage: IItemPage<IPhoto>) => {
        return { ...photoPage, value: photoPage.value.map(preparePhoto) };
      }
    );
  },
  removeAlbumPhoto: (
    fetch: (url: string, init?: RequestInit | undefined) => Promise<Response>,
    driveId: string,
    albumId: string,
    photoIds: string[]
  ): Promise<IModifyAlbumResponse> => {
    return graphResponse(
      fetch(formatURL(manageItemsApiCob, { driveId, albumId }), {
        body: JSON.stringify({
          value: photoIds.map((id: string) => ({ "@oneDrive.removed": "deleted", id: id }))
        }),
        headers: { "Content-Type": "application/json" },
        method: "POST"
      }),
      graphJSON<IModifyAlbumResponse>
    );
  },
  setCoverPhoto: (
    fetch: (url: string, init?: RequestInit | undefined) => Promise<Response>,
    driveId: string,
    albumId: string,
    coverSourceId: string
  ): Promise<void> => {
    return graphResponse<void>(
      fetch(formatURL(updateAlbumApiCob, { albumId, driveId }), {
        body: JSON.stringify({ mediaAlbum: { coverImageId: coverSourceId } }),
        headers: { "Content-Type": "application/json" },
        method: "PATCH"
      })
    );
  },
  updateAlbum: (
    fetch: (url: string, init?: RequestInit | undefined) => Promise<Response>,
    driveId: string,
    albumId: string,
    albumName: string
  ): Promise<void> => {
    return graphResponse<void>(
      fetch(formatURL(updateAlbumApiCob, { albumId, driveId }), {
        body: JSON.stringify({ name: albumName }),
        headers: { "Content-Type": "application/json" },
        method: "PATCH"
      })
    );
  }
};

const addToAlbumApiOdc = "{{vroomOrigin}}/v1.0/drive/bundles/{{albumId}}/children";
const createAlbumApiOdc = "{{vroomOrigin}}/v1.0/drives/{{driveId}}/bundles";
const deleteAlbumApiOdc = "{{vroomOrigin}}/v1.0/drive/items/{{albumId}}";
const getAlbumsApiOdc = "{{vroomOrigin}}/v1.0/drives/{{driveId}}/bundles?{{parameters}}";
const getAlbumPhotosApiOdc = "{{vroomOrigin}}/v1.0/drives/{{driveId}}/items/{{albumId}}/children?{{parameters}}";
const removeAlbumPhotoApiOdc = "{{vroomOrigin}}/v1.0/drives/{{driveId}}/bundles/{{albumId}}/children/{{photoId}}";
const updateAlbumApiOdc = "{{vroomOrigin}}/v1.0/drives/{{driveId}}/bundles/{{albumId}}";
/**
 * By default when requesting an album through single or batch calls like
 * getAlbum or getAlbums you can supply the set of properties you want back.
 *
 * The defaultAlbumSelect value represents the set of properties retrieved when the
 * caller doesn't supply an option for select.
 */
export const defaultAlbumSelectOdc = "bundle,createdDateTime,id,lastModifiedDateTime,name,parentReference,shared,tags";

export const odcAlbumApi: IAlbumApi = {
  addToAlbum: (
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    albumId: string,
    photoIds: string[]
  ): Promise<IModifyAlbumResponse> => {
    return graphResponse(
      fetch(formatURL(addToAlbumApiOdc, { albumId }), {
        body: JSON.stringify({ id: photoIds[0] }),
        headers: { "Content-Type": "application/json" },
        method: "POST"
      }),
      graphNoContent
    ).then(() => {
      return {
        value: [
          {
            id: photoIds[0]
          }
        ]
      };
    });
  },

  createAlbum: (
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    albumName: string,
    photoIds?: string[]
  ): Promise<IAlbum> => {
    return graphResponse<IAlbumODC>(
      fetch(formatURL(createAlbumApiOdc, { driveId }), {
        body: JSON.stringify({
          bundle: { album: {} },
          children: photoIds ? photoIds.map((photoId) => ({ id: photoId })) : [],
          name: albumName
        }),
        headers: { "Content-Type": "application/json" },
        method: "POST"
      })
    ).then((albumODC) => {
      return prepareAlbum(albumODC);
    });
  },

  deleteAlbum: (fetch: (url: string, init?: RequestInit) => Promise<Response>, driveId: string, albumId: string): Promise<void> => {
    return graphResponse<void>(fetch(formatURL(deleteAlbumApiOdc, { albumId }), { method: "DELETE" }), graphNoContent);
  },

  getAlbum: (
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    albumId: string,
    options?: IGetAlbumOptions
  ): Promise<IAlbum> => {
    return getItem(fetch, driveId, albumId, options || { expand: ["tags", "thumbnails"], select: defaultAlbumSelectOdc }).then((item: IItem) =>
      prepareAlbum(item as IAlbumODC)
    );
  },

  getAlbums: (
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    options?: IGetAlbumsOptions
  ): Promise<IItemPage<IAlbum>> => {
    const { expand = ["tags", "thumbnails"], orderBy = "desc", select = defaultAlbumSelectOdc, top = 100 } = options || {};
    const params: { [parameterName: string]: string } = {
      $filter: "bundle/album ne null",
      orderby: `createdDateTime ${orderBy}`,
      select,
      top: `${top}`
    };

    if (expand && expand.length) {
      params.expand = `${expand.join(",")}`;
    }

    return fetch(formatURL(getAlbumsApiOdc, { driveId, parameters: new URLSearchParams(params) }))
      .then((response) => {
        // If we get a 404 it represents no albums available.
        if (response.status === 404) {
          return { value: [] };
        }

        return graphJSON<IItemPage<IAlbumODC>>(response);
      })
      .then((albumItemPageODC: IItemPage<IAlbumODC>) => {
        const itemPage: IItemPage<IAlbum> = {
          ...albumItemPageODC,
          value: albumItemPageODC.value.map((albumODC) => prepareAlbum(albumODC))
        };
        return itemPage;
      });
  },

  getAlbumsPage: (fetch: (url: string, init?: RequestInit) => Promise<Response>, url: string): Promise<IItemPage<IAlbum>> => {
    return graphRequest<IItemPage<IAlbumODC>>(fetch, url).then((albumItemPageODC: IItemPage<IAlbumODC>) => {
      const itemPage: IItemPage<IAlbum> = {
        ...albumItemPageODC,
        value: albumItemPageODC.value.map((albumODC) => prepareAlbum(albumODC))
      };
      return itemPage;
    });
  },

  getAlbumPhotos: (
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    albumId: string,
    options?: IGetAlbumPhotosOptions
  ): Promise<IItemPage<IPhoto>> => {
    const { expand = ["tags"], select = defaultPhotoSelect, top = 100 } = options || {};
    const params: { [parameterName: string]: string } = { select, top: `${top}` };

    if (expand && expand.length) {
      params.expand = `${expand.join(",")}`;
    }

    return graphResponse(fetch(formatURL(getAlbumPhotosApiOdc, { albumId, driveId, parameters: new URLSearchParams(params) }))).then(
      (photoPage: IItemPage<INetworkPhoto>) => {
        return { ...photoPage, value: photoPage.value.map(preparePhoto) };
      }
    );
  },

  removeAlbumPhoto: (
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    albumId: string,
    photoIds: string[]
  ): Promise<IModifyAlbumResponse> => {
    return graphResponse<void>(
      fetch(formatURL(removeAlbumPhotoApiOdc, { albumId, driveId, photoId: photoIds[0] }), { method: "DELETE" }),
      graphNoContent
    ).then(() => {
      return {
        value: [
          {
            id: photoIds[0],
            "@removed": "deleted"
          }
        ]
      };
    });
  },

  setCoverPhoto: (
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    albumId: string,
    coverSourceId: string
  ): Promise<void> => {
    return graphResponse<void>(
      fetch(formatURL(updateAlbumApiOdc, { albumId, driveId }), {
        body: JSON.stringify({ bundle: { album: { coverSourceId } } }),
        headers: { "Content-Type": "application/json" },
        method: "PATCH"
      }),
      graphJSON
    );
  },

  updateAlbum: (
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    albumId: string,
    albumName: string
  ): Promise<void> => {
    return graphResponse<void>(
      fetch(formatURL(updateAlbumApiOdc, { albumId, driveId }), {
        body: JSON.stringify({ name: albumName }),
        headers: { "Content-Type": "application/json" },
        method: "PATCH"
      }),
      graphJSON
    );
  }
};

export function prepareAlbum(albumODC: IAlbumODC): IAlbum {
  const { bundle, ...albumWithoutBundle } = albumODC;
  const album: IAlbum = {
    ...albumWithoutBundle,
    mediaAlbum: {
      albumItemCount: bundle?.childCount ?? 0,
      ...(bundle?.album?.coverSourceId ? { coverImageId: bundle?.album?.coverSourceId } : {})
    }
  };
  return album;
}
